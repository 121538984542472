#reports {
    .reports-margin-title {
        margin-top: 15px;
    }
    .reports-list-item {
        width: 592px;
        margin-top: 50px;
        padding: 39px 19.5px;
        background-color: #F8F9FD;
        .accordion-details-margin {
            margin-left: 2vw;
            margin-top: 1.3vw;
            display: flex;
            flex-direction: column;
            gap: 1.33vw;
            .accordion-details-cursor {
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
        @media (max-width: 1000px) {
            width: 70%;
        }

        @media (max-width: 1536px) {
            max-width: 432px;
            padding: 29.28px 15.63px;
        }
        .reports-divider {
            margin-top: 30px;
            margin-bottom: 30px;
        }
        .accordion-style {
            display: flex;
            flex-direction: row;
            gap: 1vw;
            align-items: center;
            cursor: pointer;
        }
        .mouse-pointer {
            cursor: pointer;
        }
        .img {
            @media (max-width: 1536px) {
                width: 15px;
                height: 10px;
            }
        }
    }
}
