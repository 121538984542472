#default-layout {
  #default-content-container {
	padding-top: 2.9688vw;
	padding-bottom: 2.9688vw;
	padding-left: 37px;
  }

  .content-container {
	padding-right: 37px;
	padding-bottom: 2.9688vw;
  }

  .content-container-not-logged {
	padding-left: 7vw;
	padding-right: 9vw;
	padding-bottom: 3vw;
  }
}
