#warning-box {
	padding: 20px 25px;
	margin-bottom: 30px;
	@media (max-width: 1536px) {
		padding: 1.041vw 1.25vw;
	}
	.warning-box-margin-right {
		margin-right: 20px;
		@media (max-width: 1536px) {
			margin-right: 1.041vw;
		}
		.warning-icon {
			@media (max-width: 1536px) {
				width: 22.5px;
				height: 22.5px;
			}
		}
	}
}