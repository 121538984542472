#error-page-container {
	padding: 15%;

	#error-page-left-inner-container {
		img {
			width: 40%;
			margin-bottom: 8%;
		}

		p {
			width: 65%;
		}
	}

	#error-page-right-inner-container {
		img {
			width: 100%;
		}
	}
}

.main-page {
  display: flex;
  justify-content: center;
}

.error-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px; // equivalente ao spacing={4}
}

.error-page-content {
  width: 100%;
  
  @media (min-width: 900px) { // breakpoint md
    width: 50%;
  }
}

.server-error-page {
  display: flex;
  justify-content: center;

  &__main-container {
    width: 83.33%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 25%;
  }

  &__text-content-wrapper {
    flex: 0 0 50%;
    
    @media (max-width: 900px) {
      flex: 0 0 100%;
    }
  }

  &__illustration-wrapper {
    flex: 0 0 50%;
    
    img {
      width: 100%;
      height: auto;
    }

    @media (max-width: 900px) {
      flex: 0 0 100%;
    }
  }

  &__action-button-wrapper {
    margin-top: 32px;
  }
}