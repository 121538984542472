@use "text-field";

.custom-input {
    @extend .default-text-field;
    width: 100% !important;
    border: none !important;
    padding: 0 !important;
    margin: 16.5px 14px;
    font-weight: bold;

    &:focus-visible {
        outline: none !important;
    }
}