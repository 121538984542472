#company-details-page {
	#main-content-container {
		padding-top: 2.9688vw;
		padding-left: 37px;
		padding-right: 37px;

		@media (max-width: 1536px) {
			padding-top: 3.4723vw;
		}
	}
}

.details-company-text-field {
	height: 60px;
	
	@media (max-width: 1440px) {
		height: 45px;
	}
}

.details-company-select {
	height: 60px !important;
	margin-top: -4px;
	display: flex;
	align-items: center;
	justify-content: center;
	
	@media (max-width: 1440px) {
		height: 45px !important;
	}

	input {
		height: 100%;
	}
}

.details-divider {
	margin: 30px 0 40px 0 !important;
	
	@media (max-width: 1440px) {
		margin: 24px 0 30px 0 !important;
	}
}

#details-button-container {
	margin-top: 3vh;

	button {
		min-width: 200px !important;
		height: 50px !important;
		font-size: 16px !important;
	}
}

#details-image-container {
	width: 3vw;
	height: 3vw;
	border-radius: 50%;
	cursor: pointer;
}

#close-account-dialog {
	.MuiDialogActions-spacing {
		padding: 1.5rem !important;
	}
}