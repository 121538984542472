#menu-component-onboarding {

    padding: 54px 41px 90px;
    background-color: #F4F6FB;
    position: fixed;
    z-index: 999;

    @media (max-width: 1536px) {
        padding: 37px 30px 44px;
    }

    .grid-texts {
        padding-bottom: 50px;

        @media (max-width: 1536px) {
            padding-bottom: 42px;
        }
    }

    .extra-2 {
        padding-top: 50px;

        @media (max-width: 1536px) {
            padding-bottom: 42px;
        }
    }

    .extra-6 {
        padding-top: 50px;

        @media (max-width: 1536px) {
            padding-bottom: 42px;
        }
    }

    .menu-options {
        font-size: 1.1459vw;
        color: #B6B6B9;
        padding-right: 4.5834vw;

        @media (max-width: 1536px) {
            font-size: 0.9723vw;
            padding-right: 5.7639vw;
        }
    }

    .img {
        width: 1.1459vw;
        margin-right: 12px;
    }
}