#automatic-enrolment-ddi {
    .automatic-enrolment-ddi-title-margin {
        margin-bottom: 20px;
    }
    .automatic-enrolment-ddi-container {
        display: flex;
        flex-direction: row;
        gap: 3vw;
        margin-top: 20px;
        .automatic-enrolment-ddi-left {
            width: 50%;
            padding: 20px;
            background-color: #F8F9FD;
            .automatic-enrolment-ddi-div-margin {
                margin-top: 20px;
                margin-bottom: 20px;
            }
            .automatic-enrolment-ddi-left-container-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .automatic-enrolment-ddi-left-container-icon-text {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
                .automatic-enrolment-ddi-left-container-icon-cursor {
                    cursor: pointer;
                    margin-right: 10px;
                }
                .automatic-enrolment-ddi-left-container-active {
                    display: inline-block;
                    padding: 5px 15px;
                    border-radius: 20px;
                    background-color: black;
                    color: white;
                }
            }
        }
        .automatic-enrolment-ddi-right {
            width: 50%;
            height: 30%;
            display: flex;
            flex-direction: row;
            padding: 20px;
            background-color: #F8F9FD;
            .automatic-enrolment-ddi-right-icon {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }
    }
}


