#reports-eps {
	.reports-eps-item {
		.reports-eps-divider {
			padding-top: 15px;
			padding-bottom: 20px;
		}
		.reports-eps-warning-box {
			width: 85%;
			background-color: #f8f9fd;
			padding: 20px 25px;
			.reports-eps-warning-box-margin-right {
				margin-right: 20px;
				@media (max-width: 1536px) {
					margin-right: 1.04vw;
				}
				.reports-eps-warning-icon {
					@media (max-width: 1536px) {
						width: 22.5px;
						height: 22.5px;
					}
				}
			}
			@media (max-width: 1536px) {
				padding: 1.04vw 1.25vw;
			}
		}
		.reports-eps-download-margin {
			margin-top: 45px;
			margin-bottom: 20px;
			@media (max-width: 1536px) {
				margin-top: 2.29vw;
				margin-bottom: 1.04vw;
			}
		}
		.reports-eps-border-box {
			border: 1px solid #aaaeb7;
			width: 55%;
			padding: 30px 21px;
			@media (max-width: 1536px) {
				padding: 1.04vw 1.04vw;
			}
		}

		.eps-drop-boxes {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 15px;
		}
	}

}

.reports-eps-button {
	display: flex;
	flex-direction: column;
	&button {
		padding: 10px 15px;
		cursor: pointer;
		text-align: left;
		white-space: nowrap;
	}
	.button.enabled {
		color: #000;
		cursor: pointer;
	}
	.button.disabled {
		color: #c0c0c0;
		cursor: not-allowed;
		opacity: 0.6;
	}
	.button::before {
		content: "+ ";
		color: inherit;
		font-weight: bold;
	}
}

.reports-eps-modal-button {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	gap: 1vw;
}