@use "../pallet";

#team-member-page {
    #main-content-container {
        padding-top: 2.9688vw;
        padding-left: 37px;
        padding-right: 37px;

        @media (max-width: 1536px) {
            padding-top: 3.4723vw;
        }

        .mui-chip {
            span {
                font-size: 0.6945vw !important;
            }
        }
    }
}

#team-member-dialog {
    .active-style {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 30px;
        border-radius: 20px;
        background: #0360FD 0 0 no-repeat padding-box;
        color: white;
        font-size: 14px;
    }

    .team-member-typography-title {
        font-size: 14px;
        color: #707070;
    }

    .close-icon-button {
        margin-left: auto;
        cursor: pointer;
    }

    .team-member-typography-subtitle {
        font-size: 20px;
        color: black;
        font-weight: bold;
    }
}

#terminate-employee-dialog {
    .MuiDialogActions-root {
        padding: 24px;
    }
}

#bulk-employees-download-template {
    margin-top: 1.85vh;
    svg {
        margin-right: 0.52vw;
    }

    .download-template {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

#bulk-employees-container {
    margin-top: 7.4vh;
    #bulk-employees-upload-box {
        min-width: 100% !important;
        padding: 7.4vh 3.125vw;
        background-color: #F8FAFE;
        border: 1px dashed pallet.$primary-color;

        svg {
            margin-bottom: 2.7vh;
        }

        p:nth-child(2) {
            margin-bottom: 0.92vh;
        }

        &:hover {
            cursor: pointer;
            background-color: #e1e8ff;
        }
    }
}

#bulk-employees-progress-container {
    .bulk-employees-progress-item {
        padding: 1.85vh 1.56vw;
        border: 1px solid pallet.$border-color;

        svg {
            margin-right: 0.52vw;
        }

        .bulk-employees-progress-item-label {
            margin-top: 1.85vh;
        }

        .bulk-employees-progress-item-progress-bar {
            margin-top: 0.46vh;
            span {
                width: 100%;
                height: 0.46vh;
            }
        }
    }
}

#export-employee-data-modal {
    .MuiPaper-root {
        max-width: 1920px;
    }

    .MuiCheckbox-root {
        padding: 0;
    }

    h2 {
        margin-bottom: 10px !important;
	    padding-bottom: 0;
    }

    #export-employee-modal-content {
        margin-top: 20px;
    }

    #export-all-data {
        margin-left: 15px;
    }

    #export-employees-data-buttons {
        margin-top: 20px;

        button {
            min-width: 10.4166vw;
            max-height: 2.6041vw;
        }
    }
}