#add-single-employee-page {
	.sub-title-container {
		padding-bottom: 40px;
	}

	#onboarding-container {

		padding-top: 40px;

		@media (max-width: 1536px) {
			padding-top: 40px;
		}

		.onboarding-box {
			border: 1px solid #d1d2d6;
			margin-right: 35px;
			padding: 30px;

			@media (max-width: 1536px) {
				padding: 35px 21px 17px;
			}

			.img-1 {
				width: 1.1112vw;
				margin-bottom: 8px;

				@media (max-width: 1536px) {
					width: 1.1112vw;
				}
			}

			.img {
				width: 1.1112vw;
				margin-bottom: 10px;

				@media (max-width: 1536px) {
					width: 1.1112vw;
				}
			}

			.title-onboarding-container {
				padding-bottom: 10px;
			}

			.button-container-2 {
				padding-top: 30px;
				@media (max-width: 1536px) {
					padding-top: 20px;
				}

			}

			.default-blue-button {
				font-size: 0.8334vw !important;
				height: 2.604vw;
				min-width: 9.375vw !important;

				@media (max-width: 1536px) {
					min-width: 6.77vw !important;
					height: 1.875vw;
					font-size: 0.6945vw !important;
				}
			}
		}
	}

	.table-container {
		padding-top: 50px;
		padding-bottom: 150px;

		.people-table {
			width: 100%;
			.MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.tss-tlx3x1-MUIDataTableToolbar-root {
				padding-left: 0 !important;
			}
			.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.tss-1h5wt30-MUIDataTableSearch-searchIcon.css-i4bv87-MuiSvgIcon-root {
				padding-left: 0 !important;
			}

			.MuiTableCell-root {
				padding-left: 0 !important;
			}

		}

		.button-container {
			padding-bottom: 40px;

			button {
				height: 60px;
				min-width: 250px !important;

				@media (max-width: 1536px) {
					min-width: 185px !important;
					height: 45px;
					font-size: 12px !important;
				}
			}
		}
	}
}




