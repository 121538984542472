@use "../pallet";

.MuiSwitch-root {
	width: 60px !important;
	height: 33px !important;
	padding: 0 !important;
	border-radius: 30px !important;
	margin-right: 10px;
	
	& .MuiSwitch-switchBase {
		padding: 0;
		margin: 2px;
		transition-duration: 300ms;
		color: pallet.$gray-400;
		&.Mui-checked {
			transform: translateX(27px);
			color: #0160FD;
			& + .MuiSwitch-track {
				background-color: #A0BFF8;
				opacity: 1;
				border: 0;
			}
		}
	}
	& .MuiSwitch-thumb {
		box-sizing: border-box;
		width: 28px;
		height: 28px;

		@media (max-width: 1536px) {
			width: 23px !important;
			height: 23px !important;
		}
	}
	& .MuiSwitch-track {
		border-radius: 26px;
		background-color: pallet.$gray-300;
		opacity: 1;
	}

	@media (max-width: 1536px) {
		width: 51px !important;
		height: 27px !important;
		margin-right: 9px;
	}
}