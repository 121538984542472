@use '../pallet';

#login-image {
	background: url('../../images/login/login.jpg') no-repeat 100% 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	width: 100%;
	min-height: 100dvh;
	background-position-y: 0;
	position: relative;

	@media (max-width: 1024px) {
		visibility: hidden;
		display: none;
	}

	.logo {
		position: absolute;
		left: 2.083vw;
		top: 2.083vw;
		width: 2.864vw;
		height: 2.864vw;
	}
}

.login-container {
	display: grid;
	grid-template-columns: 1fr 1fr;

	@media (max-width: 1024px) {
		grid-template-columns: 1fr;
		padding: 2vw 20vw;
	}

	.inner-login-container {
		padding-top: 98px;

		@media (max-width: 1440px) {
			padding-top: 74px;
		}
	}

	#login-form-container {
		margin-top: 69px;
		gap: 30px;

		@media (max-width: 1440px) {
			margin-top: 52px;
			gap: 22px;
		}
	}

	.remember-me {
		margin: 30px 0;

		@media (max-width: 1440px) {
			margin: 24px 0;
		}

		span {
			font-size: 18px;
			font-weight: pallet.$medium;

			@media (max-width: 1440px) {
				font-size: 13px;
			}
		}
	}

	button {
		height: 90px;
		font-size: 25px;

		@media (max-width: 1440px) {
			height: 60px;
			font-size: 18px;
		}
	}

	.not-a-customer-yet {
		margin-top: 30px;
		font-size: 20px;
		font-weight: bold !important;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			margin: 5.2px 0 0 10px;
			height: 18px;
			width: auto;

			@media (max-width: 1440px) {
				margin: 4px 0 0 8px;
				height: 13px;
			}
		}

		@media (max-width: 1440px) {
			margin-top: 24px;
			font-size: 14px;
		}
	}
}
