@use "../../components/button";

#company-salary-page {

	.sub-title-container {
		margin-bottom: 30px;
	}

	#salaries-container {

		padding-top: 50px;

		#left-container {
			margin-right: 36px;
			#salary-items-box {
				border: 1px solid #AAAEB7;
				margin-bottom: 30px;
				.salary-item {
					padding: 30px 20px;
					@media (max-width: 1536px) {
						padding: 1.527vw 1.11vw;
					}

					#img-actions-button {
						height: 1.5vw !important;
					}
				}
			}

			.add-company-salary-button {
				@extend .default-black-button;
				height: 50px !important;
				width: 11.4584vw !important;
				@media (max-width: 1536px) {
					height: 37.5px !important;
					width: 165px !important;
				}
			}

		}

		#right-container {
			#company-salaries-information-container {
				background-color: #F8F9FD;
				padding: 30px;
				@media (max-width: 1536px) {
					padding: 20px;
				}
				img {
					margin-bottom: 1.2vh;
					@media (max-width: 1536px) {
						width: 20px;
						height: 12px;
					}
				}
			}
		}
	}
}

#salary-dialog {
	.MuiPaper-root {
		border-radius: 0 !important;
	}

	#salary-form-container {

		.company-salary-modal-text-field {
			margin-bottom: 2vh;
		}

		#pensionable-checkbox-container {
			margin-bottom: 2vh;
		}
	}

	#salary-buttons-container {
		button {
			width: 100% !important;
			height: 50px !important;
			font-size: 16px !important;
			@media (max-width: 1536px) {
				font-size: 11px !important;
				width: 145px !important;
				height: 36.45px !important;
			}
		}
	}
}