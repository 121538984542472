@use '../pallet';
@use '../components/text-field';

#basic-setup {
	padding-bottom: 135px;

	.tabs-basic-setup {
		padding-top: 50px;
		border-bottom: 0.5px solid #707070;

		.MuiTabs-indicator {
			background-color: #0160fd;
			height: 0.26vw;
		}

		.Mui-selected {
			color: #000000 !important;
			font-weight: bold !important;
		}

		.MuiTab-root {
			color: #c7c9cf;
			font-size: 0.9375vw;
			text-transform: capitalize;

			@media (max-width: 1536px) {
				font-size: 0.9028vw;
			}
		}
	}

	#step-zero {
		button {
			min-width: 230px;
		}
		#inner-step-zero {
			.main-title-container {
				padding-top: 81px;
				padding-bottom: 32px;
				border-bottom: 0.5px solid #707070;

				@media (max-width: 1536px) {
					padding-top: 50px;
					padding-bottom: 23px;
				}

				.main-title {
					font-size: 2.0834vw;
					font-weight: bold;
					color: #0160fd;
				}

				.main-subtitle {
					font-size: 1.0417vw;
					color: #adb0b8;

					@media (max-width: 1536px) {
						font-size: 0.9028vw;
					}
				}
			}

			.body-title-container {
				padding-top: 51px;
				padding-bottom: 69px;

				@media (max-width: 1536px) {
					padding-top: 37px;
					padding-bottom: 40px;
				}

				.body-title {
					font-size: 1.5625vw;
					font-weight: bold;
					color: #0160fd;

					@media (max-width: 1536px) {
						font-size: 1.5278vw;
					}
				}
			}

			.body-sub-container {
				.MuiFormControlLabel-label {
					font-size: 1.0417vw;
					font-weight: bold;
					color: #000000;
				}

				.MuiCheckbox-root.Mui-checked {
					color: #0160fd;
				}

				.MuiCheckbox-root {
					color: #abaeb7;
				}

				.body-subtitle-1 {
					font-size: 1.1459vw;
					font-weight: bold;
					color: #000000;

					@media (max-width: 1536px) {
						font-size: 1.0417vw;
					}
				}

				.body-subtitle-2 {
					font-size: 0.9375vw;
					color: #abaeb7;
					padding-bottom: 30px;

					@media (max-width: 1536px) {
						font-size: 0.9028vw;
						padding-bottom: 22px;
					}
				}
			}

			.button-container {
				padding-top: 50px;
			}
		}

		#inner-step-one {
			.body-title-container {
				padding-top: 50px;
				padding-bottom: 69px;
				border-bottom: 0.5px solid #707070;

				@media (max-width: 1536px) {
					padding-top: 50px;
					padding-bottom: 23px;
				}

				.body-title {
					font-size: 2.0834vw;
					font-weight: bold;
					color: #0160fd;
				}
			}

			.body-sub-container {
				padding-top: 51px;
				padding-bottom: 69px;

				@media (max-width: 1536px) {
					padding-top: 37px;
					padding-bottom: 40px;
				}

				.body-subtitle-1 {
					font-size: 1.1459vw;
					font-weight: bold;
					color: #000000;

					@media (max-width: 1536px) {
						font-size: 1.0417vw;
					}
				}

				.body-subtitle-2 {
					font-size: 0.9375vw;
					color: #abaeb7;
					padding-bottom: 30px;

					@media (max-width: 1536px) {
						font-size: 0.9028vw;
					}
				}
			}

			.body-sub-container-2 {
				.body-subtitle-1 {
					padding-top: 30px;
					font-size: 1.1459vw;
					font-weight: bold;
					color: #000000;

					@media (max-width: 1536px) {
						padding-top: 40px;
						font-size: 1.0417vw;
					}
				}
			}

			.body-sub-container-3 {
				.body-subtitle-1 {
					padding-top: 30px;
					font-size: 1.1459vw;
					font-weight: bold;
					color: #000000;

					@media (max-width: 1536px) {
						padding-top: 40px;
						font-size: 1.0417vw;
					}
				}
			}

			.body-sub-container-4 {
				.body-subtitle-1 {
					padding-top: 30px;
					padding-bottom: 20px;
					font-size: 1.1459vw;
					font-weight: bold;
					color: #000000;

					@media (max-width: 1536px) {
						padding-top: 20px;
						padding-bottom: 15px;
						font-size: 1.0417vw;
					}
				}

				.default-select {
					@extend .default-text-field;
				}
			}

			.button-container {
				padding-top: 50px;
			}
		}
	}

	#step-one {
		button {
			min-width: 230px;
		}
		#inner-step-zero {
			.main-title-container {
				padding-top: 81px;
				padding-bottom: 32px;
				border-bottom: 0.5px solid #707070;

				@media (max-width: 1536px) {
					padding-top: 50px;
					padding-bottom: 23px;
				}

				.main-title {
					font-size: 2.0834vw;
					font-weight: bold;
					color: #0160fd;
				}

				.main-subtitle {
					font-size: 1.0417vw;
					color: #adb0b8;
				}
			}

			.body-sub-container {
				padding-top: 38px;
			}

			.body-sub-container-1 {
				padding-top: 60px;

				@media (max-width: 1536px) {
					padding-top: 40px;
				}

				.MuiFormControlLabel-root {
					align-items: flex-start;
				}

				.checkbox-label {
					font-size: 1vw;
					font-weight: 500;
					color: #000000;

					@media (max-width: 1536px) {
						font-size: 1.25vw;
					}
				}

				.understand-more {
					font-size: 0.8334vw;
					color: #abaeb7;
					cursor: pointer;

					@media (max-width: 1536px) {
						font-size: 0.9028vw;
					}

					&:hover {
						text-decoration: underline;
					}
				}

				.MuiCheckbox-root.Mui-checked {
					color: #0160fd;
				}

				.MuiCheckbox-root {
					color: #abaeb7;
					padding-top: 3px;
				}

				.body-subtitle-1 {
					font-size: 1.1459vw;
					font-weight: bold;
					color: #000000;

					@media (max-width: 1536px) {
						font-size: 1.25vw;
					}
				}

				.body-subtitle-2 {
					font-size: 0.9375vw;
					color: #abaeb7;
					padding-bottom: 30px;

					@media (max-width: 1536px) {
						font-size: 1.0417vw;
					}
				}
			}

			.body-sub-container-2 {
				padding-top: 60px;

				@media (max-width: 1536px) {
					padding-top: 50px;
				}

				.MuiFormControlLabel-label {
					font-size: 1vw;
					font-weight: 500;
					color: #000000;

					@media (max-width: 1536px) {
						font-size: 1.25vw;
					}
				}

				.MuiCheckbox-root.Mui-checked {
					color: #0160fd;
				}

				.MuiCheckbox-root {
					color: #abaeb7;
				}

				.body-subtitle-1 {
					font-size: 1.1459vw;
					font-weight: bold;
					color: #000000;

					@media (max-width: 1536px) {
						font-size: 1.25vw;
					}
				}
			}

			.button-container {
				padding-top: 50px;
			}
		}

		#inner-step-one {
			.main-title-container {
				padding-top: 81px;
				padding-bottom: 32px;
				border-bottom: 0.5px solid #707070;

				@media (max-width: 1536px) {
					padding-top: 50px;
					padding-bottom: 23px;
				}

				.main-title {
					font-size: 2.0834vw;
					font-weight: bold;
					color: #0160fd;
				}

				.main-subtitle {
					font-size: 1.0417vw;
					color: #adb0b8;
				}
			}

			.body-sub-container {
				padding-top: 38px;

				.body-subtitle-1 {
					font-size: 1.1459vw;
					font-weight: bold;
					color: #000000;
					padding-bottom: 10px;

					@media (max-width: 1536px) {
						font-size: 1.3195vw;
					}
				}

				.body-subtitle-2 {
					max-width: 60%;
					font-size: 0.9375vw;
					color: #abaeb7;

					@media (max-width: 1536px) {
						font-size: 1.0417vw;
					}
				}
			}

			.button-container {
				padding-top: 50px;

				.button-height {
					width: 300px;
					height: 70px !important;
				}
			}
		}
	}

	#step-two {
		#inner-step-zero {
			button {
				min-width: 230px;
			}

			.main-title-container {
				padding-top: 81px;
				padding-bottom: 32px;
				border-bottom: 0.5px solid #707070;

				@media (max-width: 1536px) {
					padding-top: 50px;
					padding-bottom: 23px;
				}

				.main-title {
					font-size: 2.0834vw;
					font-weight: bold;
					color: #0160fd;
				}

				.main-subtitle {
					font-size: 1.0417vw;
					color: #adb0b8;
				}
			}

			.body-container {
				padding-top: 30px;

				.main-subtitle {
					font-size: 1.0417vw;
					color: #adb0b8;

					@media (max-width: 1536px) {
						font-size: 0.9723vw;
					}
				}
			}

			.body-container-0 {
				padding-top: 50px;

				@media (max-width: 1536px) {
					padding-top: 30px;
				}
			}

			.body-container-1 {
				.body-subtitle-1 {
					padding-top: 30px;
					font-size: 1.1459vw;
					font-weight: bold;
					color: #000000;

					@media (max-width: 1536px) {
						font-size: 1.0417vw;
					}
				}

				.MuiFormControlLabel-label {
					font-size: 0.9375vw;
					font-weight: 500;
					color: #000000;

					@media (max-width: 1536px) {
						font-size: 0.9028vw;
					}
				}
			}

			.body-container-2 {
				padding-top: 60px;

				@media (max-width: 1536px) {
					padding-top: 50px;
				}

				.body-subtitle-1 {
					padding-bottom: 20px;
					font-size: 1.1459vw;
					font-weight: bold;
					color: #000000;

					@media (max-width: 1536px) {
						font-size: 1.0417vw;
					}
				}

				.body-sub-container-2-1 {
					padding-top: 30px;

					@media (max-width: 1536px) {
						padding-top: 22px;
						padding-bottom: 22px;
					}
				}

				.default-select {
					@extend .default-text-field;
				}
			}

			.body-container-3 {
				.body-subtitle-1 {
					padding-top: 49px;
					font-size: 1.1459vw;
					font-weight: bold;
					color: #000000;

					@media (max-width: 1536px) {
						padding-top: 45px;
						font-size: 1.0417vw;
					}
				}

				.MuiFormControlLabel-root {
					align-items: flex-start;
					padding-top: 30px;

					@media (max-width: 1536px) {
						padding-top: 20px;
					}
				}

				.radio-label {
					font-size: 1.0417vw;
					color: #000000;

					@media (max-width: 1536px) {
						font-size: 0.9028vw;
					}
				}

				.radio-label-disabled {
					font-size: 1.0417vw;
					color: #b6bac5;

					@media (max-width: 1536px) {
						font-size: 0.9028vw;
					}
				}

				.radio-subtitle {
					font-size: 1.0417vw;
					color: #abaeb7;

					@media (max-width: 1536px) {
						font-size: 0.9028vw;
					}
				}

				.radio-subtitle-disabled {
					font-size: 1.0417vw;
					color: #b6bac5;

					@media (max-width: 1536px) {
						font-size: 0.9028vw;
					}
				}
			}

			.body-container-4 {
				.body-subtitle-1 {
					padding-top: 58px;
					font-size: 1.0417vw;
					font-weight: bold;
					color: #000000;

					@media (max-width: 1536px) {
						font-size: 0.9028vw;
					}
				}

				.main-subtitle {
					padding-top: 13px;
					padding-bottom: 35px;
					font-size: 0.9375vw;
					line-height: 25px;
					color: #adb0b8;

					@media (max-width: 1536px) {
						font-size: 0.8334vw;
						padding-bottom: 30px;
					}
				}

				.default-select {
					@extend .default-text-field;
				}
			}

			.button-container {
				padding-top: 40px;
			}
		}

		#inner-step-one {
			.main-title-container {
				padding-top: 81px;
				padding-bottom: 32px;
				border-bottom: 0.5px solid #707070;

				@media (max-width: 1536px) {
					padding-top: 50px;
					padding-bottom: 23px;
				}

				.main-title {
					font-size: 2.0834vw;
					font-weight: bold;
					color: #0160fd;
				}

				.main-subtitle {
					font-size: 1.0417vw;
					color: #adb0b8;
				}
			}

			.body-sub-container-1 {
				padding-bottom: 40px;

				@media (max-width: 1536px) {
					padding-bottom: 29px;
				}

				.custom-phone-input {
					@extend .default-phone-input;
				}
			}

			.padding {
				padding-top: 50px;
			}

			.body-sub-container-2 {
				padding-top: 40px;
				padding-bottom: 40px;

				@media (max-width: 1536px) {
					padding-top: 30px;
					padding-bottom: 30px;
				}

				.MuiFormControlLabel-label {
					font-size: 0.9375vw;
					font-weight: 500;
					color: #000000;

					@media (max-width: 1536px) {
						font-size: 0.8334vw;
					}
				}
			}

			.body-sub-container-3 {
				.subtitle-1 {
					font-size: 0.9375vw;
					color: #abaeb7;

					@media (max-width: 1536px) {
						font-size: 0.8334vw;
					}
				}

				.width {
					width: 65%;
				}

				.subtitle-2 {
					@extend .subtitle-1;
					padding-top: 40px;

					@media (max-width: 1536px) {
						padding-top: 30px;
					}
				}

				.subtitle-3 {
					@extend .subtitle-1;
					color: #000000;
					padding-top: 40px;

					@media (max-width: 1536px) {
						padding-top: 30px;
					}
				}
			}

			.button-grid {
				padding-top: 60px;

				@media (max-width: 1536px) {
					padding-top: 50px;
				}

				button {
					min-width: 230px;
				}
			}
		}

		#inner-step-two {
			button {
				min-width: 230px;
			}

			.main-title-container {
				padding-top: 81px;
				padding-bottom: 32px;
				border-bottom: 0.5px solid #707070;

				@media (max-width: 1536px) {
					padding-top: 50px;
					padding-bottom: 23px;
				}

				.main-title {
					font-size: 2.0834vw;
					font-weight: bold;
					color: #0160fd;
				}

				.main-subtitle {
					font-size: 1.0417vw;
					color: #adb0b8;
				}
			}

			.body-container {
				padding-top: 30px;

				@media (max-width: 1536px) {
					padding-top: 23px;
				}

				.main-subtitle {
					font-size: 1.0417vw;
					color: #adb0b8;

					@media (max-width: 1536px) {
						font-size: 0.9723vw;
					}
				}
			}

			.warning-container {
				padding-top: 50px;

				@media (max-width: 1536px) {
					padding-top: 30px;
				}
			}

			.warning-grid {
				width: 100%;
				background-color: #0c1d3a;
				padding: 23px;

				@media (max-width: 1536px) {
					padding: 14px;
				}

				.warning-subtitle {
					font-size: 1.1459vw;
					color: #ffffff;

					@media (max-width: 1536px) {
						font-size: 1.0417vw;
					}
				}
			}

			.body-container-2 {
				padding-top: 50px;

				@media (max-width: 1536px) {
					padding-top: 30px;
				}

				.subtitle {
					font-size: 0.9375vw;
					color: #000000;
					padding-bottom: 30px;

					@media (max-width: 1536px) {
						font-size: 1.0417vw;
					}
				}

				.MuiFormControlLabel-label {
					font-size: 0.8334vw;
					font-weight: 500;
					color: #000000;

					@media (max-width: 1536px) {
						font-size: 0.9028vw;
					}
				}
			}

			.button-grid {
				padding-top: 50px;
			}
		}

		#inner-step-three {
			button {
				min-width: 230px;
			}

			.main-title-container {
				padding-top: 81px;
				padding-bottom: 32px;
				border-bottom: 0.5px solid #707070;

				@media (max-width: 1536px) {
					padding-top: 50px;
					padding-bottom: 23px;
				}

				.main-title {
					font-size: 2.0834vw;
					font-weight: bold;
					color: #0160fd;
				}

				.main-subtitle {
					font-size: 1.0417vw;
					color: #adb0b8;
				}
			}

			.body-container-1 {
				padding-top: 50px;
			}

			.bank-container {
				background-color: #f2f2f4;

				.bank-logo {
					width: 100%;
				}
			}
		}
	}

	#step-three {
		button {
			min-width: 230px;
		}

		li {
			font-size: 0.9375vw;
			color: #000000;
			padding-bottom: 20px;
			padding-right: 41px;

			@media (max-width: 1536px) {
				font-size: 0.9028vw;
			}
		}

		.main-title-container {
			padding-top: 65px;
			padding-bottom: 22px;
			border-bottom: 0.5px solid #707070;

			@media (max-width: 1536px) {
				padding-top: 50px;
				padding-bottom: 23px;
			}

			.main-title {
				font-size: 2.0834vw;
				font-weight: bold;
				color: #0160fd;
			}

			.main-subtitle {
				font-size: 1.0417vw;
				color: #adb0b8;

				@media (max-width: 1536px) {
					font-size: 0.9028vw;
				}
			}
		}

		.body-container {
			padding-top: 50px;

			@media (max-width: 1536px) {
				padding-top: 30px;
			}

			.body-subtitle {
				font-size: 0.9375vw;
				color: #adb0b8;
			}

			.body-subtitle-1 {
				padding-top: 30px;
				font-size: 0.9375vw;
				color: #000000;
			}
		}

		.warning-container {
			padding-top: 60px;

			@media (max-width: 1536px) {
				padding-top: 30px;
			}
		}

		.warning-grid {
			background-color: #0c1d3a;
			padding: 23px;

			@media (max-width: 1536px) {
				padding: 12px;
			}

			.warning-subtitle {
				padding-left: 20px;
				font-size: 1.1459vw;
				color: #ffffff;

				@media (max-width: 1536px) {
					font-size: 1.1112vw;
				}
			}
		}

		.body-container-1 {
			padding-top: 50px;

			@media (max-width: 1536px) {
				padding-top: 30px;
			}

			.body-subtitle {
				font-size: 0.9375vw;
				color: #000000;

				@media (max-width: 1536px) {
					font-size: 0.9028vw;
				}
			}

			.understand-more {
				padding-top: 20px;
				font-size: 0.8334vw;
				cursor: pointer;

				@media (max-width: 1536px) {
					font-size: 0.7639vw;
				}

				a {
					color: #abaeb7;
				}

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.button-grid {
			padding-top: 70px;

			@media (max-width: 1536px) {
				padding-top: 50px;
			}
		}

		.body-container-2 {
			padding-top: 52px;

			@media (max-width: 1536px) {
				padding-top: 35px;
			}

			.MuiFormControlLabel-label {
				font-size: 0.8334vw;
				font-weight: 500;
				color: #000000;

				@media (max-width: 1536px) {
					font-size: 0.9723vw;
				}
			}
		}

		.body-container-3 {
			padding-top: 60px;

			@media (max-width: 1536px) {
				padding-top: 30px;
			}

			.padding-down {
				padding-bottom: 30px;
			}
		}

		.button-grid-1 {
			padding-top: 50px;
		}
	}
}

#direct-debit-guarantee-dialog {

	padding: 0 41px 60px;
	.MuiDialog-paper {
		border-radius: 0 !important;
		display: flex;
		flex-direction: column;
		margin: auto;
		max-width: fit-content;
	}

	.MuiDialogTitle-root {
		padding: 20px 48px;
	}

	.MuiDialogContent-root {
		padding: 0;
	}

	.dialog-close-button-container {
		display: flex;
		justify-content: flex-end;
		padding: 15px 15px 42px 0;
	}

	.dd-logo {
		width: 200px;
		height: 120px;
	}

	.dialog-title {
		color: pallet.$primary-color;
		font-weight: bold;
		font-size: 1.5626vw;

		@media (max-width: 1536px) {
			font-size: 1.5278vw;
		}
	}

	.dialog-title-subtitle {
		color: #5a5c61;
		font-size: 0.9325vw;

		@media (max-width: 1536px) {
			font-size: 0.9028vw;
		}
	}

	ul li {
		font-size: 0.8334vw;
		font-weight: normal;
		color: #000000;
		padding-bottom: 20px;
		padding-right: 41px;

		@media (max-width: 1536px) {
			font-size: 0.9028vw;
		}
	}

	ul {
		padding-left: 63px;
	}

	.MuiDialogActions-root {
		padding: 0 48px 48px 48px;
		justify-content: flex-end;
	}
}
