@use "../components/button";

.image-tax-year {
    width: inherit !important;
}

#main-page {

    .team-member-table-container {

        .cell-style {
            font-size: 16px;
            font-weight: bold;
            color: #000000;
        }

        .border {
            cursor: pointer;
            padding: 9.5px 32px;
            background-color: #000000;
            font-size: 16px;
            font-weight: bold;
            color: #FFFFFF;

        }

        .mui-chip {
            span {
                font-size: 0.6945vw !important;
            }
        }

        .run-payroll-button {
            @extend .default-black-button;
            width: 110px;
            height: 2.0834vw;
            min-height: 30px;
        }
    }
}

.main-page-fail-report {
    .reports-fps-warning-box {
        width: 100%;
        background-color: #F8F9FD;
        padding: 20px 25px;
        margin-bottom: 30px;
        @media (max-width: 1536px) {
            padding: 1.04vw 1.25vw;
        }
        .reports-fps-warning-box-margin-right {
            margin-right: 20px;
            @media (max-width: 1536px) {
                margin-right: 1.04vw;
            }
            .reports-fps-warning-icon {
                @media (max-width: 1536px) {
                    width: 22.5px;
                    height: 22.5px;
                }
            }
        }
        .link-color {
            color: blue;
            text-decoration: none;
            cursor: pointer;
        }
    }
}
