#hmrc-page {
    .left-container {
        padding-top: 26px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: #F8F9FD;

        @media (max-width: 1536px) {
            padding-top: 1.3889vw;
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .edit {
        padding-bottom: 1.0417vw;
    }

    .right-container {
        padding-left: 20px;
        padding-right: 20px;
        background-color: #F8F9FD;
    }

    .information-container {
        padding-top: 50px;

        .information-item-grid {
            padding-top: 1.5625vw;
            padding-bottom: 1.5625vw;

            @media (max-width: 1536px) {
                padding-top: 1.5278vw;
                padding-bottom: 1.5278vw;
            }
        }
    }

    .hover {
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .arrow-general {
        margin-left: 5px;
        width: 17px;
        height: 17px;
    }

    .icon {
        width: 0.9375vw;
        height: 0.9375vw;
        padding-left: 13.43px;
        padding-top: 5px;

        @media (max-width: 1536px) {
            width: 1vw;
            height: 1vw;
        }
    }

    .right-icon {
        width: 0.9375vw;
        height: 0.9375vw;

        @media (max-width: 1536px) {
            width: 1vw;
            height: 1vw;
        }
    }
}


