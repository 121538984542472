@use "sass:color";

$primary-color: #0160FD;
$secondary-color: #E6840A;
$tertiary-color: #ABAEB7;
$accent-color-1: #FF4A4A;
$accent-color-2: #F5A623;
$accent-color-3: #8B572A;

$input-background: #F4F6FB;

$border-color: #C9CCD1;
$error: #FF0000;

$medium: 500;

// grey
$gray-100: #f5f5f5;
$gray-200: #ebebeb;
$gray-300: #d4d4d4;
$gray-400: #999999;
$gray-500: #666666;
$gray-600: #3b3b3b;
$gray-700: #262626;
$gray-800: #171717;
$gray-900: #0f0f0f;

// Definindo cores com funções prontas do Sass
$color-lighten: color.scale($primary-color, $lightness: 20%);

// colors map.
$color-palette: (
		'primary': $primary-color,
		'secondary': $secondary-color,
		'tertiary': $tertiary-color,
		'accent-1': $accent-color-1,
		'accent-2': $accent-color-2,
		'accent-3': $accent-color-3,
		'background': $gray-100,
		'text': $gray-800,
		'text-light': $gray-500,
		'border': $gray-300,
		'lighten': $color-lighten,
);