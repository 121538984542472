@use "../pallet";

.typography-h1 {
	color: pallet.$primary-color;
	font-size: 3.125vw !important;
	line-height: 3.125vw !important;
	font-weight: bold !important;
	text-align: center;
	padding: 15px 0;
}

.typography-p {
	font-size: 1.145vw !important;
	line-height: 1.5vw !important;
	text-align: center;
	padding: 5px 0;
	color: black !important;

	@media (max-width: 1536px) {
		font-size: 1.11vw !important;
	}
}

.white {
	color: white !important;
}

.red-text {
	color: red !important;
}

.typography-black-header {
	color: black !important;
	font-weight: bold !important;
	font-size: 1.14vw !important;
	line-height: 1.5vw !important;

	@media (max-width: 1536px) {
		font-size: 1.38vw !important;
		line-height: 1.4vw !important;
	}
}

.typography-black-subheader {
	color: pallet.$tertiary-color;
	font-size: 0.937vw !important;
	line-height: 1.5vw !important;

	@media (max-width: 1536px) {
		font-size: 1.1vw !important;
		line-height: 1.2vw !important;
	}
}