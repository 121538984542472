@use "button";

.mui-table {
    width: 100%;
    .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.tss-tlx3x1-MUIDataTableToolbar-root {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .MuiInputBase-input.MuiInput-input.css-1x51dt5-MuiInputBase-input-MuiInput-input {
        font-weight: bold;
    }

    //Line after header
    .MuiTableHead-root .MuiTableRow-root:first-child .MuiTableCell-root:not(.MuiTableCell-fixedLeft)::before {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #d1d1d3; /* Adjust the color as desired */
    }

    .MuiTableCell-head {
        color: #0360FD;
        font-weight: bold;
        font-size: 0.9375vw;
        text-transform: uppercase;
        padding: 16px;

        @media (max-width: 1536px) {
            font-size: 0.9028vw;
        }
    }

    .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.tss-1h5wt30-MUIDataTableSearch-searchIcon.css-i4bv87-MuiSvgIcon-root {
        padding-left: 0;
    }

    .MuiInputBase-input.MuiInput-input.css-1x51dt5-MuiInputBase-input-MuiInput-input {
        width: 100% !important;
    }

    .tss-1ls58sa-MUIDataTableBodyCell-simpleCell {
        color: #B6B6B9;
        padding: 8px;
    }

    .hover {
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

}

.mui-table-no-toolbar {

    .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.tss-tlx3x1-MUIDataTableToolbar-root.css-hyum1k-MuiToolbar-root {
        display: none;
    }
}

.mui-table-no-footer {

    .MuiTableFooter-root.css-9cgc89-MuiTableFooter-root {
        display: none;
    }
}

#black-button {
    @extend .default-black-button;
    font-size: 0.7639vw !important;
    height: auto !important;
    padding: 0.8507vw !important;
}

#white-button {
    @extend .default-white-button;
    font-size: 0.7639vw !important;
    height: auto !important;
    padding: 0.8507vw !important;
}