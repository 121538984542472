#company-allowances-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .left-container {
        padding-left: 20px;
        padding-right: 20px;
        background-color: #F8F9FD;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        align-self: flex-start;
        width: 45%;
    }
    .right-container {
        padding-left: 20px;
        padding-right: 20px;
        background-color: #F8F9FD;
        margin-left: 36px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        width: 49%;
    }

    .information-container {
        padding-top: 50px;
        display: flex;
        width: 100%;
        flex-direction: row;
        .information-container-1 {

        }
        .information-item-grid {
            padding-top: 30px;
            padding-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .information-line {
                line-height: 2.3;
                margin-right: 20px;
            }

        }
    }

    .icon {
        width: 18px;
        height: 18px;
        padding-left: 13.43px;
        padding-top: 5px;
    }
}


