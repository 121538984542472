#reports-payroll-journal {
    .reports-payroll-journal-divider {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .edit-journal-box {
        background-color: #F8F9FD;
        padding: 20px 30px;
        width: 45%;
        .reports-payroll-button-margin {
            margin-bottom: 10px;
        }
    }
    .reports-payroll-journal-check-box-div {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 1.5vw;
    }
    .reports-payroll-journal-button {
        display: flex;
        flex-direction: row;
        gap: 1vw;
    }
    .reports-payroll-journal-items-per-row {
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: repeat(2, 1fr); /* 3 items per row */
        flex: 0 1 calc(100% / 3) !important;
        box-sizing: border-box;
        gap: 2vw;
        margin-bottom: 15px;
    }

}


