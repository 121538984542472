* {
	box-sizing: border-box;
}

.header {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
}

.logo {
	width: 13.958vw;
}

.button-circle {
	all: unset;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 1.562vw;
	height: 1.562vw;
	border-radius: 100%;
	border: 1px solid #000000;
	color: #000;
}

.wrapper-step {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1.041vw;
	padding-left: 10.052vw;
	padding-right: 10.052vw;
	padding-top: 5.208vw;
	padding-bottom: 1.041vw;
}

.wrapper-step-center {
	height: calc(100vh - 4.687vw);
	padding-top: 0;
}

.main-icon {
	width: 2.083vw;
}

.start-now-button {
	width: 11.458vw;
	margin-top: 1.562vw;
}

.text-wrapper {
	display: flex;
	flex-direction: column;
	gap: 1.562vw;
}

.select-wrapper {
	width: 30.781vw;
}

.buttons-wrapper {
	width: 42.708vw;
	display: flex;
	gap: 1.041vw;
}

.second-form {
	display: flex;
	flex-direction: column;
	max-width: 61.718vw;
  	gap: 1.604vw;
}

.form-wrapper {
	display: flex;
	gap: 1.875vw;
}

.phone-container {
	display: flex;
	flex-direction: column;
	gap: 0.347vw;
	width: 100%;
}

.footer-wrapper {
	display: flex;
	flex-direction: column;
}

.direct-debit-icon {
	width: 9.114vw;
}
