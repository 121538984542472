@use '../pallet';

#sign-up-image {
	background: url('../../images/sign-up/sign-up.jpg') no-repeat 100% 100%;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	min-height: 100vh;
	height: 100%;

	@media (max-width: 1180px) {
		height: 100%;
	}

	@media (max-width: 915px) {
		background: none;
		height: 0;

		padding-top: 30px;
		border: none;

		img {
			display: none;
			visibility: hidden;
		}
	}
}

#sign-up-container {
	@media (max-width: 915px) {
		max-width: 592px;
		flex-basis: 100%;
	}

	#sign-up-card {
		border-radius: 0;

		@media (min-width: 1369px) {
			max-width: 592px !important;
		}

		@media (max-width: 1536px) {
			max-width: 432px !important;
		}

		#sign-up-card-content {
			padding: 58px 46px;

			@media (max-width: 1536px) {
				padding: 50px 35px;
			}

			.sign-up-radio-group {
				margin-bottom: 50px;
			}

			label {
				height: 46px;

				@media (max-width: 1536px) {
					height: 35px;
				}
			}

			span {
				font-size: 0.937vw;
				line-height: 1vw;
			}

			@media (max-width: 915px) {
				max-width: 100%;
			}

			@media (max-width: 768px) {
				border: none;
				padding: 10px 15px;
			}
		}
	}
}

.sign-up-header {
	color: pallet.$primary-color;
	font-weight: bold !important;
	font-size: 1.3vw !important;
	line-height: 1.8vw !important;
	margin-bottom: 25px !important;
	width: 80%;

	@media (max-width: 1536px) {
		font-size: 1.25vw !important;
	}
}

button.sign-up-button {
	height: 60px;

	@media (max-width: 1536px) {
		height: 45px;
	}
}

#already-using-freepayroll {
	color: white !important;
	margin-top: 15px !important;
	width: 596px;

	@media (max-width: 1536px) {
		width: 432px;
	}

	a {
		font-weight: bold;

		&:visited {
			color: white;
		}
	}

	@media (max-width: 915px) {
		p {
			color: black;
		}

		a {
			color: pallet.$primary-color;
		}
	}

	@media (max-width: 768px) {
		p {
			color: black;
			padding-left: 15px;
		}

		a {
			color: pallet.$primary-color;
		}
	}
}

#sign-up-details {
	height: 100vh;
	overflow-y: scroll;
	width: 100%;
	background-color: white;
	padding: 7% 20%;

	@media (max-width: 1536px) {
		padding: 3.5% 20%;
	}

	@media (max-width: 915px) {
		padding: 5%;
	}

	.create-account-card-phone-label {
		margin-top: 20px;
	}

	.create-account-card-text-field {
		margin-bottom: 20px;
	}

	#google-sign-up {
		border: 1px solid pallet.$gray-300;
		border-radius: 0;
		color: rgba(204, 204, 204, 0.86);
		margin-bottom: 40px;
		height: 70px;
		font-size: 0.937vw;

		@media (max-width: 1536px) {
			height: 50px;
			font-size: 0.837vw;
			margin-bottom: 14px;
		}

		img {
			margin-right: 5%;
			visibility: visible !important;
			display: block;
			height: 30px !important;

			@media (max-width: 1536px) {
				height: 18px !important;
			}
		}
	}

	#collegia-sign-up {
		border: 1px solid pallet.$gray-300;
		border-radius: 0;
		color: black;
		margin-bottom: 10px;
		height: 70px;
		font-size: 0.937vw;

		@media (max-width: 1536px) {
			height: 50px;
			font-size: 0.837vw;
		}

		img {
			margin-right: 5%;
			visibility: visible !important;
			display: block;
			height: 30px !important;

			@media (max-width: 1536px) {
				height: 18px !important;
			}
		}
	}

	.MuiDivider-root {
		margin-bottom: 30px;

		@media (max-width: 1536px) {
			margin-bottom: 11px;
		}
	}

	.side-lines {
		color: pallet.$gray-400;
		font-size: 1.04167vw;
		padding: 0 10px;
	}

	.password-check {
		margin: 18px 0;
		height: 21px;

		@media (max-width: 1536px) {
			height: 14px;
			margin: 12px 0;
		}

		.inactive {
			color: pallet.$gray-500;
		}

		.check {
			color: pallet.$primary-color;
		}

		.error {
			color: pallet.$error;
		}

		img {
			margin-right: 20px;
			height: 21px;

			@media (max-width: 1536px) {
				height: 14px;
			}
		}

		p {
			font-size: 0.833vw;
			line-height: 0.833vw;

			@media (max-width: 1536px) {
				font-size: 0.763vw;
				line-height: 0.763vw;
			}
		}
	}

	button {
		height: 80px;

		@media (max-width: 1536px) {
			height: 60px;
		}
	}
}

#signup-collegia {
	padding: 0 41px 60px;

	.collegia-logo {
		width: 40px;
	}

	.MuiDialog-paper {
		border-radius: 0 !important;
	}

	.dialog-close-button-container {
		display: flex;
		justify-content: flex-end;
		padding: 15px 15px 42px 0;
	}

	.MuiDialogContent-root {
		display: flex;
		justify-content: center;
		padding: 0;
		overflow: visible;
	}

	.MuiDialogActions-root {
		padding: 30px 0 48px;
		justify-content: center;
	}
}

.sign-up-wrapper {
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	.sign-up-image-container {
		flex: 0 0 50%;
		width: 50%;

		@media (max-width: 915px) {
			flex: 0 0 100%;
			width: 100%;
		}
	}

	#sign-up-container {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex: 0 0 50%;
		width: 50%;
		min-height: 100vh;
		height: 100%;

		@media (max-width: 915px) {
			flex: 0 0 100%;
			width: 100%;
		}
	}
}
