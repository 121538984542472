#allowance-modal {
  .allowance-subtitle {
    margin-bottom: 20px;
    margin-top: 5px;
  }
  .allowance-modal-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    flex-direction: row;
  }
  .allowance-modal-check-box {
    margin-bottom: 15px;
  }
  .allowance-modal-employment-allowance-one-button {
    margin-left: 5px;
  }
  .allowance-modal-employment-allowance-two-button {
    margin-left: 5px;
    display: flex;
    align-items: center;
    gap: 1vw;
    flex-direction: row;
  }
}
