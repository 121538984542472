#close-tax-year {
    .warning-background {
        padding: 17px 42px;
        margin-top: 30px;
        margin-bottom: 40px;
        background-color: #0C1D3A;
        .white-and-black-warning {
            width: 40px;
            height: 40px;
        }
    }
    .close-tax-carry-over-radio-button {
        color: #ABAEB7;
        padding-top: 0;
        &.Mui-checked {
            color: #0160FD;
        }
    }
}
