@use "button";
@use "typography";
@use "text-field";
@use "select";
@use "switch";
@use "payment-schedule-information";
@use "left-menu";
@use "mui-datatable";
@use "currency-input";
@use "left-menu-onboarding";
@use "employee-summary";
@use "app-bar";
@use "mui-datatable-test";
@use "warning-box";
@use "common";
@use "approve-payroll-content";
@use "allowance-modal";

a {
	text-decoration: none;
	cursor: pointer;
}

.mb-0 {
	margin-bottom: 0 !important;
}