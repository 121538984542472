@use 'functions';
@use 'pallet';
@use 'components';
@use 'pages';

@import url('https://fonts.googleapis.com/css?family=Plus+Jakarta+Sans:300,400,500,600,700');

* {
	font-family: Plus Jakarta Sans, sans-serif !important;
}

.MuiAppBar-root {
	background-color: white !important;
	box-shadow: none !important;
	border-bottom: 0.5px solid #707070;
}

.MuiToolbar-root {
	height: 4.1667vw !important;
	padding-left: 37px;

	@media (max-width: 1536px) {
		height: 4.6528vw !important;
	}
}

.logo-icon-loading {
	animation: fadeLogo 5s infinite;
	animation-delay: 0.3s;
	margin: 0 auto;
	min-height: 80px;
	min-width: 80px;
	position: absolute;
	top: 50%;
	left: 50%;

	&::before {
		animation: fadeLogo 5s infinite;
		animation-delay: 0.15s;
	}

	&::after {
		animation: fadeLogo 5s infinite;
		animation-delay: 0.45s;
	}

	@keyframes fadeLogo {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
}

.pb-0 {
	padding-bottom: 0 !important;
}

.default-select {
	@extend .default-text-field;

	input {
		height: 100%;
		padding: 0 !important;
		width: 70% !important;
		box-sizing: border-box;
	}
}

.date-picker {
	@extend .default-text-field;

	width: 100% !important;

	div {
		width: 100%;

		input {
			width: 100%;
		}

		div {
			width: auto;

			button {
				min-width: auto !important;
				margin-right: 0px;

				svg {
					width: 1.45vw;
					height: 1.45vw;
				}
			}
		}
	}
}

.employee-active {
	background-color: #0360fd !important;
	border-radius: 16px !important;
	padding: 7px 14px;
	color: #ffffff;
	font-size: 14px !important;
}

.employee-terminated {
	background-color: #c4c4c4 !important;
	border-radius: 16px !important;
	padding: 7px 14px;
	color: #ffffff;
	font-size: 14px !important;
}

.employee-pending {
	background-color: #000000 !important;
	border-radius: 16px !important;
	padding: 7px 14px;
	color: #ffffff;
	font-size: 14px !important;
}

.divider {
	border: 1px solid #c7c9cf;
	width: 100%;
}

.mui-chip {
	height: 2.0833vw !important;

	span {
		font-size: 0.9375vw;
	}
}

#popover {
	.MuiPopover-paper {
		border-radius: 0;
		border: 1px solid #ebebeb;
		min-width: 156px;
		box-shadow: 0 3px 6px #00000029;

		.popover-list-grid {
			padding: 12px 13px;
			cursor: pointer;
			font-size: 0.7813vw !important;

			&:hover {
				background-color: #f5f5f5;
			}
		}

		.popover-icon {
			margin-right: 10px;
			width: 18.5px;

			@media (max-width: 1536px) {
				width: 14px;
			}
		}
	}
}

.team-member-menu {
	.MuiList-root {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
}

.team-member-menu-item {
	padding-top: 0.78vw !important;
	padding-bottom: 0.78vw !important;

	&:hover {
		background-color: #f4f6fc !important;
	}
}
