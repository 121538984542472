@use '../pallet';
@use 'login';

.confirm-email-wrapper {
	display: flex;
	flex-wrap: wrap;
	width: 100%;

	@media (max-width: 1025px) {
		justify-content: center;
	}
}

#confirm-email-image {
	@extend #login-image;
	background: url('../../images/confirm-email/confirm-email.jpg') no-repeat 100%
		100%;
	background-size: cover !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 50%;

	@media (max-width: 1025px) {
		width: 0px;
	}
}

#confirm-email-container {
	@extend .login-container;
	padding: 14.1146vw 5% 0;
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	width: 50%;
	height: 100vh;
	overflow-y: scroll;

	@media (max-width: 1025px) {
		width: 50vw;
	}

	.pincode-input-container {
		margin: 46px 0 !important;
		width: 100%;
		text-align: center;

		.pincode-input-text {
			border: 1px solid pallet.$border-color !important;
			margin: 0 3% !important;
			width: 75px !important;
			height: 107px !important;
			font-size: 28px;

			@media (max-width: 1798px) {
				width: 50px !important;
				height: 80px !important;
			}

			@media (max-width: 1218px) {
				width: 40px !important;
				height: 60px !important;
			}
		}

		.pincode-input-text:focus {
			border: 1px solid pallet.$primary-color !important;
		}
	}

	#resend {
		margin-bottom: 46px;

		.resend-sub {
			color: pallet.$primary-color;
			font-weight: bold;
			&:hover {
				cursor: pointer;
				text-decoration: underline;
			}
		}
	}
}
