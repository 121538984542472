#auto-enrolment-settings {
    .auto-enrolment-page-title {
        margin-bottom: 30px;
    }
    .auto-enrolment-settings-information-container {
        padding-top: 50px;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;
    }
    .auto-enrolment-settings-left-container {
        padding: 20px;
        background-color: #F8F9FD;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        align-self: flex-start;
        width: 35vw;
        @media (max-width: 1046px) {
            width: 30vw;
        }
        .auto-enrolment-settings-left-container-edit {
            align-items: center;
            margin-bottom: 15px;
            .auto-enrolment-settings-typography-edit {
                cursor: pointer;
                text-align: end;
            }
        }
        .auto-enrolment-settings-divider {
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .icon {
            margin-left: 5px;
            width: 18px;
            height: 18px;
        }
        .auto-enrolment-setting-left-container-items {
            display: flex;
            flex-direction: row;
            margin-top: 20px;
            justify-content: space-between;

            .auto-enrolment-setting-left-container-typography {
                text-align: end;
            }
        }
    }
    .auto-enrolment-settings-right-container {
        padding: 1.5625vw;
        display: flex;
        flex-direction: row;
        background-color: #F8F9FD;
        margin-left: 36px;
        flex-wrap: wrap;
        align-content: start;
        gap: 10px;
        height: 30%;
    }
}

.auto-enrolment-settings-modal {
    .auto-enrolment-settings-modal-title {
        margin-top: 20px;
    }
    .auto-enrolment-settings-modal-contributions {
        display: flex;
        flex-direction: row;
        gap: 2vw;
    }
    .auto-enrolment-settings-modal-buttons {
        margin-top: 30px;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
    }
    .auto-enrolment-settings-modal-error {
        color: red;
        font-size: 0.8vw;
    }
    .auto-enrolment-settings-modal-checkbox {
        display: flex;
        justify-content: start;
        margin-top: 30px;
    }
}