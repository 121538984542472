@use "../../pallet";

.text-field-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 5px;
}

.text-field-label {
	font-size: 16px;

	@media (max-width: 1440px) {
	  font-size: 12px;
	}
}

.text-field-content {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	width: 100%;
	border: transparent solid 1px;
	background: #F4F6FB;
	box-sizing: border-box;
	padding: 0 15px;
	font-weight: 400;
	font-size: 18px;
  
	@media (max-width: 1440px) {
		padding: 0 10px;
	}
}

.text-field-disabled {
	input {
		color: #8b8b8b;
		font-size: 13px;
		padding: 0 10px;
	}
}

.text-field-input {
	height: 100%;
	width: 100%;
	padding: 23px 0;
	box-sizing: border-box;
	outline: none;
	border: none;
	font-size: 18px;
	background-color: transparent;

	@media (max-width: 1440px) {
		font-size: 13px;
		padding: 17px 0;
	}
}

.text-field-end-adornment {
	margin-right: 5px;
	font-size: 18px;
	
	@media (max-width: 1440px) {
		font-size: 13px;
		margin-right: 10px;
	}

	a {
		color: pallet.$primary-color;
	}
}

.text-field-helper-text {
	font-size: 16px;

	@media (max-width: 1440px) {
	  font-size: 12px;
	}
}

.text-field-helper-text-error {
	color: pallet.$error;
}

.error {
	border: pallet.$error solid 1px;
}
