@use "../components/button";

#enhanced-policy {
    .enhanced-policy-container {
        padding-left: 37px;
        padding-right: 37px;

        .awesome-policy {
            max-width: 435px;
            max-height: 80px;
            border: 1px solid #AAAEB7;
            padding: 13px 5px 13px 16px;
            align-items: center;
            align-content: center;
            margin-bottom: 20px;
            cursor: pointer;
            @media (max-width: 1536px) {
                max-width: 315px;
                max-height: 58px;
                padding: 13px 5px 13px 16px;
                .img {
                    width: 15.45px !important;
                    height: 15.45px !important;
                }
            }

        }
        .statutory-style {
            background-color: #F8F9FD;
            padding: 30px 40px;
            max-width: 592px;
            @media (max-width: 1536px) {
                padding: 2.083vw 1.597vw;
                max-width: 436px;
            }
        }
        .enhanced-policy-divider {
            padding-bottom: 70px;
            padding-top: 30px;
            width: 100%;
            @media (max-width: 1536px) {
                padding-bottom: 3.472vw;
                padding-top: 1.66vw;
            }
        }
        .img {
            @media (max-width: 1536px) {
                width: 9.77px;
                height: 9.77px;
            }
        }
        .create-button {
            @extend .default-black-button;
            min-width: 220px !important;
            max-height: 50px !important;
            max-width: 220px;
            @media (max-width: 1536px) {
                max-height: 2.6vw !important;
                max-width: 11.458vw !important;
                min-width: 11.458vw !important;
            }
        }
    }

}

#enhanced-policy-dialog {
    .MuiDialog-paper {
        border-radius: 0 !important;
        padding: 30px 30px;
        max-width: 530px;
        min-width: 530px;
        max-height: 390px;
        @media (max-width: 1536px) {
            padding: 1.527vw 2.013vw;
            max-width: 432px;
            min-width: 432px;
        }
    }
    .dialog-container {
        width: 512px;
        height: 70px;
        padding: 13px 5px 13px 16px;
        border: 1px solid #AAAEB7;
        align-items: center;
        align-content: center;
        cursor: pointer;
        @media (max-width: 1536px) {
            width: 432px;
            height: 51px;
            padding: 13px 5px 13px 16px;
        }
        .img {
            @media (max-width: 1536px) {
                width: 15.56px !important;
                height: 15.56px !important;
            }
        }
    }
    .company-wide-button-cancel {
        @extend .default-white-button;
        margin-top: 30px;
        min-width: 200px !important;
        height: 50px !important;
        @media (max-width: 1536px) {
            min-width: 145.95px !important;
            height: 36.49px !important;
        }
    }
}

#company-wide-enhanced-pay {
    .MuiDialog-paper {
        border-radius: 0 !important;
        padding: 50px;
        max-width: 900px;
        max-height: 640px;
        @media (max-width: 1536px) {
            max-width: 666px;
            max-height: 544px;
            padding: 2.569vw;
        }
    }
    .img {
        @media (max-width: 1536px) {
            width: 13.19px;
            height: 13.23px;
        }
    }
    .company-wide-enhanced-pay-margin-title {
        margin-bottom: 50px;
        @media (max-width: 1536px) {
            margin-bottom: 38px;
        }
    }
    .policy-cursor {
        cursor: pointer;
        margin-top: 30px;
        max-width: 200px;
        align-items: center;
        margin-bottom: 70px;
        @media (max-width: 1536px) {
            margin-bottom: 3.68vw;
            .img {
                height: 11px;
                width: 11px;
            }
        }
    }
    .company-wide-enhanced-pay-divider {
        padding-bottom: 40px;
        padding-top: 40px;
        width: 100%;
        @media (max-width: 1536px) {
            padding-bottom: 2.083vw;
            padding-top: 2.083vw;
        }
    }
    .create-policy-button-black {
        @extend .default-black-button;
        height: 50px !important;
        min-width: 200px !important;
        @media (max-width: 1536px) {
            height: 36.49px !important;
            min-width: 147px !important;
        }
    }
    .create-policy-button-white {
        @extend .default-white-button;
        height: 50px !important;
        min-width: 200px !important;
        @media (max-width: 1536px) {
            height: 36.49px !important;
            min-width: 147px !important;
        }
    }
}

