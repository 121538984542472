@use "../pallet";

.default-text-field {
	background: pallet.$input-background;
	font-size: 0.937vw !important;
	border-radius: 0 !important;
	height: 3.125vw;
	display: flex;
	align-items: center;

	.MuiOutlinedInput-root {
		border-radius: 0 !important;
		height: 3.125vw;
		font-size: 0.937vw !important;

		.Mui-error {
			border: 1px solid red !important;
		}
	}

	.MuiOutlinedInput-notchedOutline {
		border: none !important;
	}

	.MuiFormHelperText-root {
		margin: 0;
		color: red;
		background-color: #ffffff !important;
	}

	img {
		cursor: pointer;
	}

	.Mui-error:nth-child(1) {
		border: 1px solid red;
	}
}

.custom-height {
	@media (max-width: 1536px) {
		height: 52px !important;

		.MuiOutlinedInput-root {
			height: 52px !important;
		}
	}
}

.default-text-field.Mui-error {
	border: 1px solid red !important;
}

.default-input-label {
	font-size: 0.781vw !important;
	margin-bottom: 5px;
	color: black !important;

	@media (max-width: 1536px) {
		font-size: 0.781vw !important;
	}
}

.default-phone-input {
	@extend .default-text-field;
	width: 100%;
	padding: 1.197vw;

	p {
		font-size: 1.041vw !important;
	}

	button {
		height: 3.125vw !important;
		width: 3.125vw !important;

		img {
			height: auto !important;
			width: 100% !important;
		}
	}

	.MuiOutlinedInput-root {
		height: 100% !important;
		width: 100% !important;
	}

	.Mui-error {
		border: 1px solid red !important;
	}
}
