.tutorials-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  @media (max-width: 1440px) {
    gap: 16px;
  }
}

.tutorials-category-items {
  @extend .tutorials-container;
}

.tutorials-item {
  background: #f4f6fd;
  width: 100%;
  box-sizing: border-box;
  padding: 18px 30px;
  display: flex;
  gap: 20px;

  @media (max-width: 1440px) {
    padding: 12px 20px;
    gap: 16px;
  }
}

.tutorials-item-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 1440px) {
    gap: 16px;
  }
}

.tutorials-item-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.tutorials-item-heading-start {
  display: flex;
  gap: 38px;

  @media (max-width: 1440px) {
    gap: 32px;
  }
}

.tutorials-item-heading-icon {
  width: 16px;

  @media (max-width: 1440px) {
    width: 12px;
  }
}

.tutorials-item-body {
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 1440px) {
    gap: 16px;
  }
}

.tutorials-item-body-button {
  all: unset;
  background: #0360fd;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;

  @media (max-width: 1440px) {
    width: 32px;
    height: 32px;
  }
}

.tutorials-item-body-button-icon {
  width: 13px;

  @media (max-width: 1440px) {
    width: 10px;
  }
}
