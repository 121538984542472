@use "../pallet";
@use "../components/button";
@use "../components/text-field";

#employee-record-page {

	overflow: hidden;

	#img-actions-button {
		height: 1vw !important;
	}

	.mui-chip {
		height: 1.6667vw !important;

		span {
			font-size: 0.6945vw !important;
		}
	}

	.tabs-employee-record {
		padding-top: 32px;
		border-bottom: 0.5px solid #707070;

		.MuiTabs-indicator {
			background-color: #0160fd;
			height: 0.26vw;
		}

		.Mui-selected {
			color: #000000 !important;
			font-weight: bold !important;
			padding: 0 3vw;
			overflow: hidden;
		}

		.MuiTab-root {
			color: #c7c9cf;
			font-size: 0.9375vw;
			text-transform: capitalize;
			padding: 0 3vw;
			overflow: hidden;
			//padding-left: 45px;
			//padding-right: 45px;

			@media (max-width: 1536px) {
				font-size: 0.9028vw;
			}
		}
	}


	#general {
		padding-top: 50px;
		padding-bottom: 200px;

		.hover {
			&:hover {
				cursor: pointer;
				text-decoration: underline;
			}
		}

		.arrow-general {
			margin-left: 5px;

			.MuiSvgIcon-root {
				width: 0.8334vw;
				height: 0.8334vw;
			}
		}

		.mui-chip-general {
			background-color: #ffffff;
			border: 1px solid #000000;
			color: #000000;
			cursor: pointer;

			&:hover {
				background-color: #000000;
				color: #ffffff;

			}
		}

		.employee-record-buttons {
			padding-top: 50px;

			.employee-record-buttons-save {
				@extend .default-black-button;
				height: 50px;
				padding: 14.5px 81px;

				@media (max-width: 1536px) {
					height: 37.5px;
					padding: 11px 62px;
				}
			}

			.employee-record-buttons-cancel {
				@extend .default-white-button;
				height: 50px;
				padding: 14.5px 81px;

				@media (max-width: 1536px) {
					height: 37.5px;
					padding: 11px 62px;
				}
			}
		}

		.information-item-grid {
			background-color: #f8f9fd;
		}

		.icon {
			color: #000000;
			width: 18px;
			height: 18px;
			padding-left: 13.43px;
		}
	}

	#pension {
		padding-top: 80px;

		.pension-card {
			background-color: #f8f9fd;
			padding: 26px 20px 23px;

			.information-item-grid {
				padding-top: 1.4167vw;
				padding-bottom: 1.4167vw;

				@media (max-width: 1536px) {
					padding-top: 1.1112vw;
					padding-bottom: 1.1112vw;
				}
			}
		}

		.opt-in-button {
			padding: 1vw;
		}

		.icon {
			width: 18px;
			height: 18px;
			padding-left: 13.43px;
		}
	}

	#salary {
		padding-top: 50px;

		.first-title-grid {
			padding-bottom: 30px;
		}

		.pay-schedule-default {
			border: 1px solid #aaaeb7;
			padding-bottom: 1.5104vw;
			padding-left: 20px;
			max-width: 30.8334vw;
			padding-top: 1.5104vw;
			cursor: pointer;
		}

		.salary-default {
			border: 1px solid #aaaeb7;
			padding-bottom: 1.5104vw;
			padding-left: 20px;
			max-width: 30.8334vw;
			padding-top: 1.5104vw;
			cursor: pointer;
			margin-top: 30px;
		}

		.divider {
			width: 100%;
		}

		.salary-information-container-1 {
			padding-top: 28px;
			padding-left: 20px;
			padding-bottom: 30px;
			background-color: #f8f9fd;
		}

		.salary-information-container-2 {
			padding-top: 30px;
			padding-bottom: 28px;
			padding-left: 20px;
			background-color: #f8f9fd;
		}
	}

	#taxes {
		.styled-grid {
			background-color: #f8f9fd;
			padding: 20px 20px 30px;
			height: fit-content;
		}
		.font-bold {
			font-weight: bold;
		}
		.font-normal {
			font-weight: normal !important;
		}

		.employee-record-buttons {
			padding-top: 50px;

			.employee-record-buttons-save {
				@extend .default-black-button;
				height: 50px;
				padding: 14.5px 81px;

				@media (max-width: 1536px) {
					height: 37.5px;
					padding: 11px 62px;
				}
			}

			.employee-record-buttons-cancel {
				@extend .default-white-button;
				height: 50px;
				padding: 14.5px 81px;

				@media (max-width: 1536px) {
					height: 37.5px;
					padding: 11px 62px;
				}
			}
		}

		.icon-img {
			width: 1.4584vw;
			margin-top: 0.57vw;
		}

		.add-pay-item-button {
			min-width: 10.41vw;
			min-height: 2.6vw;
			margin-top: 2.6vw;

			@media (max-width: 1440px) {
				max-height: 2.6vw;
			}
		}
	}

	#maternity-paternity {
		.sick-leave-weekly-earnings-statutory-sick-pay-box {
			padding: 12.48px 42px;
			width: 100%;
			border: 1px solid transparent;
			background-color: #0c1d3a;
			margin-bottom: 20px;
			@media (max-width: 1536px) {
				margin-top: 22.75px;
				padding: 0.8667vw 2.9167vw 0.8667vw 1.9167vw;
			}

			.sick-leave-weekly-earnings-statutory-sick-pay-margin-left {
				margin-right: 30px;
				@media (max-width: 1536px) {
					margin-right: 12.87px;
				}
			}

			.sick-leave-weekly-earnings-statutory-sick-pay-img {
				@media (max-width: 1536px) {
					width: 30px;
					height: 30px;
				}
			}
		}

		.maternity-paternity-container {
			padding-top: 2.6042vw;

			.statutory-style {
				height: fit-content;
				background-color: #f8f9fd;
				padding: 1.5625vw 2.0834vw;

				@media (max-width: 1536px) {
					padding: 1.5278vw;
				}
			}
		}

		.MuiFormControlLabel-root {
			margin-left: 0 !important;
		}
		.maternity-paternity-button {
			@extend .default-black-button;
			width: 200px;
			max-height: 50px;
			margin-bottom: 50px;
			margin-top: 40px;
		}
	}

	#sick-leave {
		margin-top: 50px;
		.sick-leave-weekly-earnings-statutory-sick-pay-box {
			padding: 12.48px 42px;
			width: 100%;
			border: 1px solid transparent;
			background-color: #0c1d3a;
			margin-bottom: 20px;
			@media (max-width: 1536px) {
				margin-top: 22.75px;
				padding: 0.8667vw 2.9167vw 0.8667vw 1.9167vw;
			}

			.sick-leave-weekly-earnings-statutory-sick-pay-margin-left {
				margin-right: 30px;
				@media (max-width: 1536px) {
					margin-right: 12.87px;
				}
			}

			.sick-leave-weekly-earnings-statutory-sick-pay-img {
				@media (max-width: 1536px) {
					width: 30px;
					height: 30px;
				}
			}
		}

		.sick-leave-main {
			.sick-leave-subtitle-margin {
				margin-top: 10px;
				margin-bottom: 40px;
				@media (max-width: 1536px) {
					margin-bottom: 2.08vw;
				}
			}
			.sick-leave-table-img {
				width: 20px;
				height: 20px;
				margin-right: 10px;
				@media (max-width: 1536px) {
					width: 15px;
					height: 15px;
					margin-right: 7px;
				}
			}
			.sick-leave-button {
				@extend .default-black-button;
				width: 220px;
				height: 50px;
				margin-top: 50px;
				@media (max-width: 1536px) {
					width: 165px;
					height: 37.5px;
					margin-top: 2.6vw;
				}
				.sick-leave-plus-white {
					margin-right: 10px;
					@media (max-width: 1536px) {
						width: 9.77px;
						height: 9.77px;
					}
				}
			}
		}
		.sick-leave-day {
			.sick-leave-day-subtitle-margin {
				margin-top: 10px;
				margin-bottom: 40px;
				@media (max-width: 1536px) {
					margin-bottom: 2.08vw;
				}
			}
			.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.css-ahj2mt-MuiTypography-root {
				font-size: 13px;
				font-weight: bold !important;
			}
			.sick-leave-day-subtitle-last-sick-day-margin {
				margin-top: 50px;
				@media (max-width: 1536px) {
					margin-top: 2.569vw;
				}
				.sick-leave-day-subtitle-last-sick-radio-margin {
					margin-top: 10px;
					@media (max-width: 1536px) {
						margin-top: 1.597vw;
					}
					.sick-leave-day-subtitle-last-sick-day-input {
						margin-top: 20px;
						@media (max-width: 1536px) {
							margin-top: 1.041vw;
						}
					}
				}
			}
			.sick-leave-day-buttons {
				margin-top: 51px;
				@media (max-width: 1536px) {
					margin-top: 39.5px;
				}
				.sick-leave-day-black-button {
					margin-right: 20px;
					@extend .default-black-button;
					width: 200px;
					height: 50px;
					@media (max-width: 1536px) {
						width: 150px;
						height: 37.5px;
					}
				}
				.sick-leave-day-white-button {
					@extend .default-white-button;
					width: 200px;
					height: 50px;
					@media (max-width: 1536px) {
						width: 150px;
						height: 37.5px;
					}
				}
			}
		}
		.sick-leave-weekly-earnings {
			.sick-leave-weekly-earnings-average-weekly-margin {
				margin-top: 30.49px;
				@media (max-width: 1536px) {
					margin-top: 1.736vw;
				}
				.sick-leave-weekly-earnings-text-field-input {
					@extend .default-text-field;
					width: 435px !important;
					height: 60px !important;
					@media (max-width: 1536px) {
						width: 326px !important;
						height: 45px !important;
					}
				}
			}
			.sick-leave-weekly-earnings-statutory-sick-pay-box {
				padding: 15.48px 42px;
				width: 1063px;
				height: 80px;
				margin-top: 30px;
				border: 1px solid transparent;
				background-color: #0c1d3a;
				@media (max-width: 1536px) {
					margin-top: 22.75px;
					padding: 1.04vw 2.43vw 1.04vw 2.43vw;
					width: 830px;
				}
				.sick-leave-weekly-earnings-statutory-sick-pay-margin-left {
					margin-right: 30px;
					@media (max-width: 1536px) {
						margin-right: 12.87px;
					}
				}
				.sick-leave-weekly-earnings-statutory-sick-pay-img {
					@media (max-width: 1536px) {
						width: 30px;
						height: 30px;
					}
				}
			}
			.sick-leave-weekly-earnings-statutory-need-help-box {
				padding: 15.48px 42px;
				width: 1063px;
				height: 80px;
				margin-top: 30px;
				margin-bottom: 50px;
				border: 1px solid #d6d5e1;
				background-color: white;
				@media (max-width: 1536px) {
					margin-top: 1.5625vw;
					margin-bottom: (37.5);
					padding: 1.04vw 2.43vw 1.04vw 2.43vw;
					width: 830px;
				}
				.sick-leave-weekly-earnings-statutory-need-help-margin-left {
					margin-right: 30px;
					@media (max-width: 1536px) {
						margin-right: 12.87px;
					}
				}
				.sick-leave-weekly-earnings-statutory-need-help-img {
					@media (max-width: 1536px) {
						width: 30px;
						height: 30px;
					}
				}
				.sick-leave-weekly-earnings-statutory-need-help-button {
					@extend .default-black-button;
					width: 200px;
					height: 45px;
					@media (max-width: 1536px) {
						width: 150px;
						height: 33.75px;
					}
				}
			}
			.sick-leave-weekly-earnings-statutory-continue-button {
				@extend .default-black-button;
				margin-right: 20px;
				width: 200px;
				height: 50px;
				@media (max-width: 1536px) {
					margin-right: 15px;
					width: 150px;
					height: 37.5px;
				}
			}
			.sick-leave-weekly-earnings-statutory-discard-button {
				@extend .default-white-button;
				width: 200px;
				height: 50px;
				@media (max-width: 1536px) {
					width: 150px;
					height: 37.5px;
				}
			}
		}
		.sick-leave-work-weekly {
			.sick-leave-work-weekly-check-box {
				margin-top: 51.49px;
				@media (max-width: 1536px) {
					margin-top: 2.7vw;
				}
				.css-ahj2mt-MuiTypography-root {
					font-weight: bold;
					font-size: 18px;
					@media (max-width: 1536px) {
						font-size: 13px;
					}
				}
			}
			.sick-leave-work-weekly-need-help-box {
				padding: 20px 42px 20px 42px;
				width: 1063px;
				height: 100px;
				margin-top: 30px;
				margin-bottom: 50px;
				border: 1px solid #d6d5e1;
				background-color: white;
				@media (max-width: 1536px) {
					margin-top: 1.562vw;
					margin-bottom: (37.5);
					padding: 1.04vw 2.43vw 1.04vw 2.43vw;
					width: 830px;
					height: 80px;
				}
				.sick-leave-work-weekly-need-help-margin-left {
					margin-right: 30px;
					@media (max-width: 1536px) {
						margin-right: 12.87px;
					}
				}
				.sick-leave-work-weekly-need-help-img {
					@media (max-width: 1536px) {
						width: 30px;
						height: 30px;
					}
				}
				.sick-leave-work-weekly-need-help-button {
					@extend .default-black-button;
					width: 200px;
					height: 45px;
					@media (max-width: 1536px) {
						width: 150px;
						height: 33.75px;
					}
				}
			}
			.sick-leave-work-weekly-continue-button {
				@extend .default-black-button;
				margin-right: 20px;
				width: 200px;
				height: 50px;
				@media (max-width: 1536px) {
					margin-right: 15px;
					width: 150px;
					height: 37.5px;
				}
			}
			.sick-leave-work-weekly-discard-button {
				@extend .default-white-button;
				width: 200px;
				height: 50px;
				margin-bottom: 50px;
				@media (max-width: 1536px) {
					width: 150px;
					height: 37.5px;
				}
			}
		}
		.sick-leave-summary {
			.sick-leave-summary-description-content {
				margin-top: 45.49px;
				margin-bottom: 50px;
				width: 750px;
				@media (max-width: 1536px) {
					margin-top: 2.7vw;
					margin-bottom: 2.6vw;
					width: 548px;
				}
				.sick-leave-summary-description-title-margin {
					margin-bottom: 30px;
					@media (max-width: 1536px) {
						margin-bottom: 1.66vw;
					}
				}
				.sick-leave-summary-description-text {
					margin-bottom: 20px;
					@media (max-width: 1536px) {
						margin-top: 1.11vw;
					}
				}
				.sick-leave-summary-divider {
					margin-top: 40px;
					margin-bottom: 40px;
					color: #707070;
					width: 100%;
					@media (max-width: 1536px) {
						margin-top: 2.083vw;
						margin-bottom: 2.083vw;
					}
				}
			}

			.sick-leave-summary-continue-button {
				@extend .default-black-button;
				margin-right: 20px;
				width: 200px;
				height: 50px;
				@media (max-width: 1536px) {
					margin-right: 15px;
					width: 150px;
					height: 37.5px;
				}
			}
			.sick-leave-summary-discard-button {
				@extend .default-white-button;
				width: 200px;
				height: 50px;
				margin-bottom: 50px;
				@media (max-width: 1536px) {
					width: 150px;
					height: 37.5px;
				}
			}
		}
	}
}

#salary-modal {
	.MuiDialog-paper {
		border-radius: 0 !important;
		padding: 30px 30px 51px;
	}

	.dialog-close-button-container {
		display: flex;
		justify-content: flex-end;
		padding: 0 10px 10px 5px;
	}

	.MuiDialogTitle-root {
		padding: 0 0 30px;
	}

	.MuiDialogContent-root {
		display: flex;
		padding: 0;
		overflow: visible;
	}

	.MuiDialogActions-root {
		padding: 20px 0 0;
	}

	.text-underlined {
		&:hover {
			cursor: pointer;
			text-decoration: underline;
		}
	}
}

#bank-details-container {
	margin-top: 4.62vh;

	div:nth-child(1) {
		padding-top: 0;
	}
}

#bank-details-buttons-container {
	margin-top: 4.62vh;

	button {
		width: 10.4vw;
		margin-right: 1.04vw;
	}
}

#pay-items-schemes {
	margin-top: 1.04vw;
	margin-bottom: 2.6vw;

	@media (max-width: 1440px) {
		margin-top: 0.69vw;
		margin-bottom: 2.08vw;
	}

	.pay-item-scheme-container {
		border: 1px solid pallet.$border-color !important;
		cursor: pointer;
		padding: 1.04vw;

		&:hover {
			background-color: rgba(pallet.$primary-color, 0.05);
		}
	}
}

.pay-items-scheme-cancel {
	@extend .default-button-size;
	display: block !important;
}

#student-loans-cards-container {
	margin-top: 2.6vw;
	.pay-item-scheme-card {
		border: 1px solid pallet.$border-color;
		margin-bottom: 1.56vw;
		padding: 1.04vw;

		.pay-item-scheme-card-subtitle {
			margin-bottom: 1.04vw;
		}
	}

	#student-loans-buttons-container {
		margin-top: 1.56vw;
		margin-bottom: 5.2vw;

		button {
			margin-right: 1.04vw;
		}
	}
}

#employee-schemes-container {
	margin-top: 3.125vw;

	.employee-active-student-loan-card {
		margin-top: 0.83vw;
		margin-bottom: 0.83vw;
		border: 1px solid pallet.$border-color;
		padding: 1.041vw;
	}
}

#year-to-date-form {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 25px;
	padding-top: 50px;
	padding-bottom: 50px;

	@media (max-width: 1440px) {
		gap: 15px;
		padding-top: 30px;
		padding-bottom: 30px;
	}

	.fields-group {
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		gap: 20px;

		@media (max-width: 1440px) {
			gap: 15px;
		}
	}

	.save-button-container {
		width: 300px;

		@media (max-width: 1440px) {
			width: 250px;
		}
	}
}
