.Wrapper {
	display: flex;
	gap: 0.625vw;
	align-items: center;
}

.Icon {
	width: 0.937vw;
}

.Message {
	font-size: 0.729vw;
}

.checked {
	color: #0360fd;
}

.inactive {
	color: #abaeb7;
}

.error {
	color: #ff0000;
}
