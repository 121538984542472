.WhatIsPayScheduleContainer {
    display: flex;
    flex-direction: column;
    gap: 10px
}

.GeneralInformationContainer {
    display: flex;
    flex-direction: column;
    gap: 2vw;
    max-width: 90%;

    .GeneralInformationRow {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 3vw;
        width: 100%;
    }
}

.RequireClientApprovalContainer {
    display: flex;
    flex-direction: column;
    gap: 2vw;

    .NoteContainer {
        padding: 1vw;
        background-color: #F4F6FC;
        @media (max-width: 1536px) {
            max-width: 65vw;
        }
    }
}

.UponApprovalClosePayrollContainer {
    display: flex;
    flex-direction: column;
    gap: 2vw;

    .NoteContainer {
        padding: 1vw;
        background-color: #F4F6FC;
        @media (max-width: 1536px) {
            max-width: 65vw;
        }
    }
}

.PayScheduleConfirmDialog {
    display: flex;
    flex-direction: column;
    gap: 1.604vw;
    padding: 1.604vw;

    .List {

        display: flex;
        flex-direction: column;
        gap: 1vw;

        .ListItem {
            margin-left: 20px;
        }
    }

    .ButtonsDiv {
        display: flex;
        flex-direction: row;
        gap: 3vw;
    }
}