#employee-summary {

	padding-top: 57px;
	padding-left: 30px;
	padding-right: 30px;
	height: 100vh;

	background-color: #F4F6FC;

	.employee-summary-title {
		padding-top: 40px;
	}

	.employee-name {
		color: #0360FD;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	.add-pay-item {
		font-size: 0.625vw;
		color: #0360FD;
		font-weight: bold;
		cursor: pointer;

		@media (max-width: 1536px) {
			font-size: 0.78vw;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	.pay-item-edit {
		&:hover {
			text-decoration: underline;
		}
	}

	.employee-summary-items {
		padding-top: 20px;
	}
}