#payroll-content {
	display: flex;
	flex-direction: column;
	gap: 1.562vw;
	width: 100%;

	.heading {
		display: flex;
		justify-content: space-between;
	}

	.tabs-run-payroll {

		border-bottom: 1px solid #707070;

		.MuiTabs-indicator {
			background-color: #0160FD;
			height: 0.260vw;
		}

		.Mui-selected {
			color: #000000 !important;
			font-weight: bold !important;
		}

		.MuiTab-root {
			color: #C7C9CF;
			font-size: 0.9375vw;
			text-transform: capitalize;

			@media (max-width: 1536px) {
				font-size: 0.9028vw;
			}
		}
	}
}

#TableContainer {
	display: flex;
	flex-direction: column;

	.TableRowHeaderContainer {
		display: flex;
		flex-direction: column;
	}

	.TableRowContainer {
		display: flex;
		flex-direction: column;
		cursor: pointer;

		&:hover {
			background-color: #F4F6FC;
		}
	}

	.TableRowHeader, .TableRow {
		display: grid;
		grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr;
		align-items: center; /* Vertically center items */
		height: 4vw; /* Increase row height as needed */
	}

}

#Payroll-Summary {
	display: grid;
	grid-template-columns: 1.75fr 1fr;
	gap: 10vw;
	width: 100%;

	.Summary {
		display: flex;
		flex-direction: column;
		gap: 2.042vw;
		width: 100%;

		.InformationBox {
			display: flex;
			flex-direction: column;
			gap: 1vw;
			background: #F8F9FD;
			padding: 2vw;

			.InfoText {
				padding-right: 7vw;
			}
		}

	  	.ApproveButtons {
			display: flex;
		    flex-direction: row;
		    gap: 1.042vw;

		    button {
				width: 8.5vw;
		    	height: 2.5vw !important;
		    }
		}

		.PaymentsOverview{
			display: flex;
			flex-direction: column;
			gap: 2.542vw;
		}

		.PaymentOverviewLine {
			display: flex;
			justify-content: space-between;
		}

		.BreakdownSections {
			display: flex;
			flex-direction: column;
			gap: 2.042vw;
		}
	}


	.BoxSummary {
		display: flex;
		flex-direction: column;
		padding: 30px;
		background: #F8F9FD;
		gap: 2vw;
		height: fit-content;
		width: 100%;
		overflow: hidden; /* Prevent the container from expanding */

		.FirstBox {
			display: flex;
			flex-direction: column;
			gap: 2.042vw;

			.ViewPayments {
				cursor: pointer;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

#EmployeeSummary {
	padding: 57px 30px;
	background-color: #F4F6FC;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 2.042vw;

	.Header {
		display: flex;
		flex-direction: row;
		align-items: center;
		position: relative;

		.Avatar {
			width: 3.766vw;
			height: 3.766vw;
			border-radius: 50%;
			background: #FFF;
			border: 0.5px solid #000;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 1vw;
		}

		.TaxCodeLabel{
			font-size: 0.731vw;
		}

		.EmployeeSummaryActionsContainer {
			position: absolute;
			width: 100%;
			display: flex;
			justify-content: flex-end;
			gap: 1.042vw;

			.EmployeeSummaryIcon{
				width: 1.302vw;
				transform: rotateZ(90deg);
			}
		}
	}

	.AddPayItem {
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	.EmployeePaymentBreakdown {
		display: flex;
		flex-direction: column;
		gap: 1.242vw;

		.PaymentBreakdownLine {
			display: flex;
			justify-content: space-between;
		}
	}
}

.EmployeeSummaryActions{
	display: flex;
	flex-direction: column;
	padding: 0 !important;
	width: fit-content;
	z-index: 999999 !important;
	position: relative;

	.EmployeeSummaryAction{
		all: unset;
		cursor: pointer;
		display: flex;
		align-items: center;
		text-wrap: nowrap;
		width: 100%;
		gap: 0.625vw;
		height: 2.343vw;
		padding: 0.625vw;
		box-sizing: border-box;

		&:hover {
			background-color: #f4f6fc;
		}

		&:disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}
	}
}