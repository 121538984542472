#reports-taxes-filling {

    display: flex;
    flex-direction: column;
    gap: 1.562vw;

    .reports-taxes-fillings-selects {
        display: flex;
        flex-direction: row;
        gap: 1vw;
        justify-content: space-between;
    }
    .reports-taxes-fillings-payment-summary {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 20px;
    }
}