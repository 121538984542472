@use "../pallet";

.people-table {
    width: 100%;
    .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.tss-tlx3x1-MUIDataTableToolbar-root {
        padding-left: 0 !important;
        padding-right: 0 !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: flex-start;
        height: 100px !important;
    }
    .css-l4u8b9-MuiInputBase-root-MuiInput-root::before {
        border-bottom: none !important;
    }
    .css-l4u8b9-MuiInputBase-root-MuiInput-root::after {
        border-bottom: none !important;
    }

    .MuiInputBase-input.MuiInput-input.css-1x51dt5-MuiInputBase-input-MuiInput-input {
        font-weight: bold;
    }

    .MuiTableHead-root .MuiTableRow-root:first-child .MuiTableCell-root {
        position: relative;
    }

    .MuiTableHead-root .MuiTableRow-root:first-child .MuiTableCell-root:not(.MuiTableCell-fixedLeft)::before {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #d1d1d3; /* Adjust the color as desired */
    }

    .tss-1fz5efq-MUIDataTableToolbar-left {
        background-color: pallet.$input-background;
        width: 100% !important;
        max-height: 50px;
        padding-top: 8px;
    }

    .MuiTableCell-head {
        color: pallet.$primary-color;
        font-weight: bold;
        font-size: 0.9375vw;
    }

    .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.tss-1h5wt30-MUIDataTableSearch-searchIcon.css-i4bv87-MuiSvgIcon-root {
        padding-left: 0;
    }

    .MuiInputBase-input.MuiInput-input.css-1x51dt5-MuiInputBase-input-MuiInput-input {
        width: 100% !important;
    }

    .tss-1ls58sa-MUIDataTableBodyCell-simpleCell {
        color: #B6B6B9;
    }

    .font-size {
        font-size: 0.8334vw;
    }

    .status {
        width: fit-content;
        padding: 7px 14px;
        border-radius: 16px;
        color: white;
        font-size: 0.7292vw;
    }

    .first-column-cell {
        color: black;
        font-size: 0.9375vw;
        font-weight: bold;
    }

    .hover {
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .mui-table-tool-bar {
        padding-top: 10px;
        padding-left: 0;
        position: sticky;
        top: 0;
    }

}