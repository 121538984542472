#reports-p11 {
    .reports-p11-item {
        .reports-p11-divider-title-margin {
            margin-bottom: 20px;
            margin-top: 20px;
        }
        .reports-p11-content {
            display: flex;
            flex-direction: row;
            gap: 2vw;
            .reports-p11-years {
                width: 50%;
                height: 30%;
                background-color: #F8F9FD;
                padding: 25px 35px;
                .reports-p11-years-row {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    .reports-p11-years-img-year {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 1vw;
                        .reports-p11-years-img {
                            width: 1vw;
                            height: 1vw;
                        }
                    }
                    .reports-p11-arrow {
                        width: 15px;
                        height: 20px;
                        @media (max-width: 1536px) {
                            width: 10px;
                            height: 15px;
                        }
                    }
                }

                .reports-p11-years-divider-margin {
                    margin-top: 15px;
                    margin-bottom: 15px;
                }

                @media (max-width: 1536px) {
                    padding: 1.934vw 1.03vw;
                }

            }
            .reports-p11-box-border {
                margin-top: 10px;
                width: 100%;
                padding: 30px 20px;
                border: 1px solid #AAAEB7;
                margin-bottom: 80px;
                @media (max-width: 1536px) {
                    padding: 1.52vw 1.04vw;
                }
                .reports-p11-box-border-title {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
                .reports-p11-divider {
                    padding-top: 40px;
                    padding-bottom: 40px;
                    width: 100%;
                    @media (max-width: 1536px) {
                        padding-top: 2.08vw;
                        padding-bottom: 2.08vw;
                    }
                }
            }
        }
    }
}


