@use "../pallet";

.default-black-button {
	background-color: black !important;
	color: white !important;
	height: 71px;
	font-size: 1.04vw !important;
	font-weight: bold !important;
	border-radius: 0 !important;
	text-transform: capitalize !important;

	@media (max-width: 1536px) {
		height: 52px;
		font-size: 0.97vw !important;
	}
	
	@media (max-width: 1024px) {
		height: 50px;
		font-size: 20px !important;
	}

	@media (max-width: 425px) {
		height: 50px;
		font-size: 20px !important;
	}

	@media (max-width: 900px) {
		width: 100% !important;
	}

	&:disabled {
		background-color: rgba(0,0,0,0) !important;
		color: pallet.$border-color !important;
		border: 1px solid pallet.$border-color !important;

		p {
			color: pallet.$border-color !important;
		}
	}
}

.default-white-button {
	@extend .default-black-button;
	background-color: #FFFFFF !important;
	color: #000000 !important;
	border: 1px solid #D6D9E0 !important;
}

.default-blue-button {
	@extend .default-black-button;
	background-color: pallet.$primary-color !important;
}

.default-button-size {
	min-width: 10.41vw !important;
	min-height: 2.60vw !important;

	@media (max-width: 1440px) {
		max-height: 2.60vw;
	}
}