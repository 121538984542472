#ApprovePayrollContainer {

    padding: 20px 30px 40px 30px;
    display: flex;
    flex-direction: column;
    gap: 1.104vw;
    width: 100%;

    .ModalTitle {
        margin-bottom: 0.5vw;
    }

    .ListComponent {
        display: flex;
        flex-direction: column;
        gap: 1vw;
        margin-bottom: 1vw;
    }

    .ApproveButtonsContainer{
        margin-top: 2vw;
        display: flex;
        justify-content: flex-start;
        gap: 2vw;

        button {
            max-width: 10vw;
        }
    }

    .ForceSubmissionLabel {
        font-size: 0.9vw;
    }

    .CheckBox {
        display: flex;
        justify-content: start;
    }
}

#RejectPayrollContainer {

    padding: 20px 30px 40px 30px;

    .ListComponent {
        display: flex;
        flex-direction: column;
        gap: 1vw;
        margin-bottom: 1vw;
    }

    .RejectPayrollTextarea {
        margin-top: 1vw;
        width: 100%;
        border: none;
        background: #F4F6FB;
        height: 9vw;
        resize: none;
        font-size: 0.9vw;

        ::placeholder {
            font-size: 0.9vw;
        }

        &:focus {
            outline: none;
        }
    }

    .ApproveButtonsContainer{
        margin-top: 2vw;
        display: flex;
        justify-content: flex-start;
        gap: 2vw;

        button {
            max-width: 10vw;
        }
    }
}