.ColumnButton {
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5vw;
    width: fit-content;
}

.Arrow {
    width: 0.729vw;
    height: 0.729vw;
}

.FilterIcon {
    width: 1.145vw !important;
}

.FilterChip {
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.364vw;
    width: fit-content;
}

.CrossIcon {
    width: 0.729vw;
}

.FilterText {
    line-height: 0px;
}

.Desc {
    transform: rotate(180deg);
}

.Typography {
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
    margin-right: 1vw;
}