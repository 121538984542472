#menu-component {
    padding: 1.40625vw 2.0416vw 2.40625vw 1.0416vw;
    background-color: #F4F6FB;
    z-index: 99;
    transition: 0.2s; /* Smooth transition */
    width: 5.2084vw;

    .grid-texts {
        height: 3.489vw !important;
        display: flex;
        flex-wrap: unset;
        align-items: center;
        padding-top: 1.0417vw;
        padding-bottom: 1.0417vw;

        @media (max-width: 1536px) {
            font-size: 0.9028vw;
        }
    }

    .menu-options {
        margin: 0;
        margin-left: 12px;
        white-space: nowrap;
        font-size: 0.9375vw;
        color: #B6B6B9;
        cursor: pointer;

        @media (max-width: 1536px) {
            font-size: 0.9028vw;
        }
    }

    .new {
        margin: 0 0 0 12px;
        white-space: nowrap;
        font-size: 0.7028vw;
        padding: 5px;
        color: #FFF;
        background-color: #0360FD;

        @media (max-width: 1536px) {
            font-size: 0.7028vw;
        }
    }

    .menu-options-active {
        margin: 0;
        margin-left: 12px;
        white-space: nowrap;
        font-size: 0.9375vw;
        color: #000000;
        font-weight: bold;
        cursor: pointer;

        @media (max-width: 1536px) {
            font-size: 0.9028vw;
        }
    }

    .sub-menu-options {
        font-size: 0.8333vw;
        padding-bottom: 17px;
        color: #B6B6B9;
        cursor: pointer;
    }

    .sub-menu-options-active {
        font-size: 0.8333vw;
        color: #000000;
        padding-bottom: 17px;
        cursor: pointer;
    }

    .img {
        width: 1.0677vw;
        margin-left: 1vw;
    }

    .lock {
        opacity: 0.3;
    }

    .menu-accordion-style {
        border: none;
        background-color: transparent !important;
        box-shadow: none;

        .MuiAccordionSummary-root {
            min-height: 0 !important;
            padding: 0 !important;
            margin: 0 !important;
        }

        .MuiAccordionSummary-content {
            min-height: 0 !important;
            padding: 0 !important;
            margin: 0 !important;
        }

        .MuiAccordionSummary-root.Mui-expanded {
            min-height: 0 !important;
            padding: 0 !important;
            margin: 0 !important;
        }
    }
}

.expanded {
    max-width: 18.75vw !important; /* Expanded width */
    width: 18.75vw !important;
}