#header {
	padding: 59px 193px;
	
	
	img {
		width: 9.4167vw;
	}

	@media (max-width: 1440px) {
		padding: 44px 153px;

		img {
			width: 149px;
		}
	}
}
