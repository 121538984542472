#run-payroll-page {

    .default-blue-button {
        padding: 0.52vw 5.312vw;
        height: 2.604vw;
    }
    .import-button {
        padding: 0.52vw 3.312vw;
        height: 2.604vw;
        border-radius: 0;
        margin-right: 10px;
        border: 1px solid #D6D9E0 !important;
        text-transform: capitalize;
        @media (max-width: 900px) {
            width: 100%;
            margin-right: 0;
        }
    }

    .team-member-table-container {
        padding-top: 50px;

    }

    .tabs-run-payroll {

        border-bottom: 1px solid #707070;

        .MuiTabs-indicator {
            background-color: #0160FD;
            height: 0.260vw;
        }

        .Mui-selected {
            color: #000000 !important;
            font-weight: bold !important;
        }

        .MuiTab-root {
            color: #C7C9CF;
            font-size: 0.9375vw;
            text-transform: capitalize;

            @media (max-width: 1536px) {
                font-size: 0.9028vw;
            }
        }
    }
}

