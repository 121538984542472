#onboarding-dashboard-page {
	#main-content-container {
		padding-top: 57px;
	}

	.sub-title-container {
		padding-top: 14px;
	}

	#stepper-container {
		padding-top: 60px;
		padding-bottom: 200px;

		.MuiStepLabel-label {
			font-size: 0.9375vw;
		}

		.MuiStepContent-root {
			height: 100px;

			@media (max-width: 1536px) {
				height: 80px;
			}
		}

		#step-content-container {
			background-color: #0f2861;
			padding: 30px 30px 15px;

			.step-white-button {
				min-width: 7.812vw;
			}
		}
	}
}
