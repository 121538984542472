#statutory {
  .statutory-divider-margin {
    margin-bottom: 40px;
    margin-top: 20px;
  }
  .statutory-fields {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    .statutory-text-field {
      flex: 1 1 calc(50% - 1rem);
      max-width: calc(50% - 1rem);
    }
  }
  .statutory-row {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1vw;
    flex-direction: row;
  }
}