#reports-p60 {
    .reports-title-margin {
        margin-top: 1vw;
        margin-bottom: 2vw;
    }
    .reports-p60-item {
        .reports-p60-description {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1vw;
            width: 85%;
            background-color: #F4F6FC;
            padding-left: 24px;
            padding-top: 24px;
            padding-bottom: 24px;
            @media (max-width: 1536px) {
                padding-left: 1.25vw;
                padding-top: 1.04vw;
                padding-bottom: 1.04vw;
                .reports-p60-description-warning-image {
                    width: 22.5px;
                    height: 22.5px;
                }
            }
        }
        .reports-p60-download-title {
            margin-top: 45px;
            @media (max-width: 1536px) {
                margin-top: 3.4vw;
            }
        }
        .reports-p60-download-sub-title {
            margin-bottom: 30px;
            @media (max-width: 1536px) {
                margin-bottom: 1.66vw;
            }
        }
        .reports-p60-item-map {
            background-color: #F8F9FD;
            width: 50%;
            padding: 27px 20px 30px 20px;
            @media (max-width: 1536px) {
                padding: 1.388vw 1.04vw 1.66vw 1.04vw;
            }
            .reports-p60-item-map-divider {
                padding-top: 20px;
                padding-bottom: 20px;
                width: 100%;
            }
            .reports-p60-map-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .reports-p60-last-year {
                    padding-left: 5px;
                    padding-right: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 11vw;
                    height: 2vw;
                    border-radius: 1.5vw;
                    background: #0360FD 0 0 no-repeat padding-box;
                }
                .reports-p60-map-download-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 1vw;

                    :hover {
                        cursor: pointer;
                    }

                    .img {
                        cursor: pointer;
                        @media (max-width: 1536px) {
                            width: 16.29px !important;
                            height: 15.42px !important;
                        }
                    }
                }
            }
        }

    }
}


