@use "../../pallet";

#add-single-employee-page {

    #tab-container {
        .tabs-basic-setup {

            padding-top: 27px;
            border-bottom: 0.5px solid #707070;

            .MuiTabs-indicator {
                background-color: #0160FD;
                height: 0.260vw;
            }

            .Mui-selected {
                color: #000000 !important;
                font-weight: bold !important;
            }

            .MuiTab-root {
                color: #C7C9CF;
                font-size: 0.9375vw;
                text-transform: capitalize;

                @media (max-width: 1536px) {
                    font-size: 0.9028vw;
                }
            }
        }

        #personal-information-container {
            padding-top: 50px;

            .button-container {
                padding-bottom: 100px;
                display: flex;
                gap: 1vw;

                button {
                    min-width: 185px !important;
                }
            }
        }

        .info-text {
            font-size: 14px;
            color: #AAAEB7;
            line-height: 15px;

            @media (max-width: 1536px) {
                font-size: 10px;
            }
        }

        #employment-details-container {
            padding-top: 50px;

            @media (max-width: 1536px) {
                padding-top: 30px;
            }

            .info-text {
                font-size: 14px;
                color: #AAAEB7;
                line-height: 15px;

                @media (max-width: 1536px) {
                    font-size: 10px;
                }
            }

            .button-container {
                padding-top: 50px;
                padding-bottom: 100px;

                @media (max-width: 1536px) {
                    padding-top: 30px;
                }

                button {
                    min-width: 185px !important;
                }
            }
        }

        #previous-employment-details-container {
            padding-top: 50px;

            @media (max-width: 1536px) {
                padding-top: 30px;
            }

            .cumulative-calc {

                margin: 0;

                span {
                    font-size: 0.9375vw;
                    font-weight: pallet.$medium;

                    @media (max-width: 1536px) {
                        font-size: 1.0417vw;
                    }
                }

            }

            .button-container {
                padding-bottom: 100px;

                button {
                    min-width: 185px !important;
                }
            }
        }
    }

}