#reports-fps {
    .reports-fps-item {
        .reports-fps-divider {
            padding-top: 30px;
            padding-bottom: 50px;
            width: 85%;
            @media (max-width: 1536px) {
                padding-top: 1.736vw;
                padding-bottom: 2.569vw;
            }
        }
        .reports-fps-warning-box {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1vw;
            width: 85%;
            background-color: #F8F9FD;
            padding: 20px 25px;
            margin-bottom: 30px;
            @media (max-width: 1536px) {
                padding: 1.04vw 1.25vw;
            }
        }
        .reports-fps-border-box {
            border: 1px solid #AAAEB7;
            width: 55%;
            padding: 30px 21px;
            @media (max-width: 1536px) {
                padding: 1.04vw;
                width: 50%;
            }
        }
        .reports-fps-year-download {
            background-color: #F8F9FD;
            padding: 20px;
            width: 33%;
            margin-bottom: 50px;
            .reports-fps-year-download-divider {
                padding-bottom: 24px;
                padding-top: 24px;
                width: 100%;
            }
            .reports-fps-accordion-style {
                box-shadow: none;
                .accordion-details {
                    margin-top: 15px;
                    cursor: pointer;
                }
                .reports-fps-accordion-content-style {
                    margin-top: 10px;
                    cursor: pointer;
                }
                .reports-fps-download-icon {
                    width: 14px;
                    height: 13px;
                    @media (max-width: 1536px) {
                        width: 12px;
                        height: 11px;
                    }
                }

            }
        }
    }
}

#report-fps-modal {
    .report-fps-modal-title {
        margin-top: 20px;
        margin-bottom: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .reports-fps-modal-buttons {
        display: flex;
        flex-direction: row;
        width: 30%;
        gap: 1vw;
    }
    .fps-report-img-style {
        cursor: pointer;
        width: 24.29px;
        height: 23px;
        @media (max-width: 1536px) {
            width: 17.92px;
            height: 16.97px;
        }
    }
}