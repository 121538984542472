.app-bar {
	border-bottom: 0.4px solid #707070 !important;
}

.app-bar-final-grid {
	width: fit-content;
	display: flex;
	align-items: center;
	padding: 0 40px;
	cursor: pointer;

	.app-bar-title {
		font-size: 0.9375vw;
	}
}

.app-bar-full-logo {
	width: 13.4167vw;
}

.app-bar-logo {
	width: 43px;

	@media (max-width: 1536px) {
		width: 33px;
	}
}

#right-menu-drawer {
	.MuiDrawer-paper {
		padding-top: 3.5vw;
		background-color: #000 !important;
		max-width: 20%;
	}

	#right-menu-drawer-container {
		padding-left: 3vw;
		width: 20vw;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		#right-menu-drawer-name {
			margin-bottom: 2vh;
		}

		.right-menu-item {
			width: 100%;
			cursor: pointer;
			margin: 2vh 0;
			display: flex;
			align-items: center;

			.right-menu-icon {
				width: 1.04vw;
				margin-right: 1vw;
			}
		}
	}
}
