@use "../../components/button";

#reports-dialog {
    .MuiDialog-paper {
        border-radius: 0 !important;
        max-width: 1220px;
        min-width: 670px;
        max-height: 390px;
        padding: 15px;
        @media (max-width: 1536px) {
            padding: 15px;
            max-width: 900px;
            min-width: 495px;
        }
    }
    .tss-1cdcmys-MUIDataTable-responsiveBase {
        overflow: hidden;
    }
    .MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium.tss-10syd3x-MUIDataTableHeadCell-root.tss-gm6zfk-MUIDataTableHeadCell-fixedHeader.css-1ygcj2i-MuiTableCell-root{
        text-align: end;
    }
}

.column-style {
    display: flex;
    justify-content: flex-end;
    .img-style {
        cursor: pointer;
        width: 24.29px;
        height: 23px;
        @media (max-width: 1536px) {
            width: 17.92px;
            height: 16.97px;
        }
    }
}

.download-all-button {
    display: flex;
    justify-content: flex-end;
    padding: 17px 0 16px;
    position: relative;
    @media (max-width: 1536px) {
        padding: 25px 0 24px;
    }
    .button {
        @extend .default-black-button;
        max-width: 150px !important;
        height: 40px !important;
        @media (max-width: 1536px) {
            max-width: 110px !important;
            height: 30px !important;
        }
    }
}

.download-all-button:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #d1d1d3;
}