@use "../pages/employee-record";
@use "select";

#pay-schedule-information {

	padding-top: 57px;
	padding-left: 37px;
	padding-right: 37px;

	.pay-schedule-information-text-field {
		@extend .default-text-field;
		font-weight: bold !important;
	}
	.pay-schedule-information-label {
		@extend .default-input-label;
		color: #ABAEB7 !important;
		font-size: 16px !important;
	}
	.pay-schedule-information-select {
		@extend .default-select;
		font-weight: bold !important;

	}
	.pay-schedule-information-date-picker {
		@extend .default-select;
		width: 100%;
		font-weight: bold !important;
	}
	.bold-input {
		font-weight: bold;
	}
	.pay-schedule-information-calendar {
		height: 340px;
		padding-top: 20px;
		width: 532px;
		background: #FFFFFF;
		box-shadow: 0 0 15px #00000029;
		opacity: 1;
		border: none;
		.react-calendar__tile--now {
			background-color: black;
			color: white;
			font-weight: bold;
		}
		.react-calendar__tile {
			font-size: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex: 0 !important;
			padding-left: 17px;
			padding-right: 17px;
			margin-left: 20px;
			margin-right: 20px;
		}
		.react-calendar__tile--active {
			color: white;
			background-color: #0160FD;
			font-weight: bold;
		}

		.react-calendar__navigation button {
			display: none;
		}
		.react-calendar__navigation .react-calendar__navigation__label {
			display: inline-block;
			padding-left: 18px;
			color: #0160FD;
			text-transform: capitalize;
			font-size: 26px;
			font-weight: bold;
			margin-bottom: 10px;
			text-align: start;
		}
		.react-calendar__navigation {
			position: relative;
		}
		.react-calendar__navigation::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 3.5%;
			right: 4%;
			height: 1px;
			background-color: #CCCBD9;
		}
		.grayed-date {
			color: #CCCBD9;
		}
		.react-calendar__month-view__weekdays__weekday {
			font-weight: bold;
			font-size: 16px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex: 0 !important;
			padding-left: 17px;
			padding-right: 17px;
			margin-left: 20px;
			margin-right: 20px;
		}
		.react-calendar__month-view__weekdays__weekday abbr {
			text-decoration: none;
		}
		.weekend-date {
			color: black;
		}
		.selected-day {
			width: 1px;
			height: 1px;
			background-color: red;
			color: white;
			text-align: center;
			line-height: 15px;
			font-size: 16px;
		}
		.weekend-date.react-calendar__tile--active {
			color: white;
			background-color: #0160FD;
			font-weight: bold;
		}
	}
	.pay-schedule-information-typography {
		color: #000000;
		font-weight: bold;
		font-size: 18px;
	}
	.pay-schedule-information-date-information {
		color: #ABAEB7;
		font-size: 18px;
		padding-top: 10px;
	}
	.pay-schedule-information-button {
		@extend .default-black-button;
		text-transform: capitalize !important;

		@media (max-width: 1536px) {
			height: 45px !important;
			min-width: 185px !important;
		}
	}
	.square {
		display: flex;
		width: 12px;
		height: 12px;
		background-color: white;
	}
	.square-current-date {
		@extend .square;
		background-color: black !important;
	}
	.square-selected-date {
		@extend .square;
		background-color: #0160FD !important;
	}
	.date-deadline {
		color: #ABAEB7;
		font-size: 12px;
	}

}
#pay-schedule-switch {
	margin-right: 0;
	margin-top: 1.5vw;
	@media (max-width: 1199px) {
		margin-top: 0;
	}
	.css-j204z7-MuiFormControlLabel-root {
		margin-right: 0 !important;
	}
	.MuiSwitch-root {
		width: 60px !important;
		height: 32px !important;
		@media (max-width: 1536px) {
			width: 45px !important;
			height: 25px !important;
		}
	}
	.MuiSwitch-root .MuiSwitch-switchBase {
		color: #BEC3CE;
		background-color: transparent;
	}
	.MuiSwitch-root .MuiSwitch-thumb {
		width: 28px !important;
		height: 28px !important;
		@media (max-width: 1536px) {
			width: 21px !important;
			height: 21px !important;
		}
	}
	.MuiSwitch-root .MuiSwitch-track {
		background-color: #E4E9F5;
	}
	.MuiSwitch-root .MuiSwitch-switchBase {
		transform: translateX(2px);
	}
	.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked {
		transform: translateX(26px);
		@media (max-width: 1536px) {
			transform: translateX(19px);
		}
	}
	.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
		background-color: #A0BFF8;
	}
	.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
		color: #0160FD;
	}
}

#pay-schedule-bureau-modal {
	@extend #salary-modal;
	.pay-schedule-bureau-modal-warning-img {
		width: 2.6vw;
		height: 2.6vw;
		margin-right: 0.6vw;
	}
}

#pay-schedule-automatically-close-note {
	padding: 20px;
	background-color: #F4F6FC;
	@media (max-width: 1536px) {
		max-width: 65vw;
	}
}

.pay-schedule-free-payroll-button-style {
	display: flex;
	flex-direction: row;
	gap: 2vw;
}

.pay-schedule-information-button-2 {
	margin-top: 2vw;
	width: 250px;
	height: 50px;
}