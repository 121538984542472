@use "../pallet";

#auto-enrolment-page {
	display: flex;
	flex-direction: column;

	.left-container {
		padding-top: 26px;
		padding-left: 20px;
		padding-right: 20px;
		background-color: #f8f9fd;

		@media (max-width: 1536px) {
			padding-top: 20px;
			padding-left: 15px;
			padding-right: 15px;
		}

		.icon {
			margin-left: 5px;
			width: 18px;
			height: 18px;
		}
	}

	.right-container {
		padding-left: 20px;
		padding-right: 20px;
		background-color: #f8f9fd;
	}

	.auto-enrolment-page-information-container {
		padding-top: 50px;
		display: flex;
		flex-direction: row;
		margin-bottom: 30px;
		.auto-enrolment-page-total {
			padding: 5px;
			border: 1px solid #E0E0E0;
			margin-right: 10px;
		}
	}

	.auto-enrolment-page-table {
		margin-top: 10px;
	}
}

#auto-enrolment-menu {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 30px;

	@media (max-width: 1440px) {
		gap: 20px;
	}

	.auto-enrolment-data {
		@extend #auto-enrolment-menu;
		margin-top: 30px;
	}

	.auto-enrolment-data-row {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}

	.auto-enrolment-data-item {
		display: flex;
		flex-direction: column;
		gap: 10px;

		@media (max-width: 1440px) {
			gap: 8px;
		}
	}

	.auto-enrolment-data-item-value {
		max-width: 80%;
	}

	.navigation-list {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 36px;
		margin-top: 60px;

		@media (max-width: 1440px) {
			gap: 20px;
		}
	}

	.navigation-list-item {
		display: flex;
		flex-direction: column;
		background: #f8f9fd;
		padding: 24px 30px;
		gap: 8px;
		cursor: pointer;

		@media (max-width: 1440px) {
			gap: 6px;
		}

		&:hover {
			border: 0.5px solid pallet.$primary-color;
		}
	}

	.navigation-list-item-icon {
		width: 20px;

		@media (max-width: 1440px) {
			width: 16px;
		}
	}
}

.auto-enrolment-table-dot {
  display: flex;
  flex-direction: column;
  gap: 1vw;

  .auto-enrolment-table-dot-item {
    padding: 10px;
    cursor: pointer;
  }
}