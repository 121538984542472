#completed-payroll-approval {

	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 2.604vw;

	.TitleContainer {
		display: flex;
		align-items: center;
		flex-direction: row;
		gap: 1vw;
		width: 100%;

		.RejectedTitle {
			color: #FF0045 !important;
		}
	}

	.CustomIcon {
		width: 3vw;
	}

	.PayrollInformation {
		display: flex;
		flex-direction: column;
		gap: 1.342vw;
	}

	.WhatHappensNow {
		background: #f8f9fd;
		width: 39.01vw;
		padding: 1.562vw;

		display: flex;
		flex-direction: column;
		gap: 1.042vw;

		.RejectExplanation {
			line-height: 1.5;
		}

		.ListItem {
			padding-left: 1.5vw;
		}
	}

	.DownloadInstructions {
		all: unset;
		cursor: pointer;
		font-size: 0.833vw;
		color: #000000;
		text-decoration: underline;
	}

	.BrilliantButton {
		width: 13.416vw;
		height: 2.604vw !important;
	}
}
