@use "../components/button";

#pay-items-page {
    .team-member-table-container {
        padding-top: 50px;
    }

    .default-blue-button {
        font-size: 0.9375vw !important;
        min-width: 14.5834vw !important;
    }
    .pay-items-new-pay-item {
        max-width: 52.08vw;
        @media (max-width: 1536px) {
            max-width: 39.09vw;
        }
    }
    .pay-items-new-pay-item-margin {
        @extend .pay-items-new-pay-item;
        margin-top: 50px;
        @media (max-width: 1536px) {
            margin-top: 2.70vw;
        }
    }

    th {
        div {
            svg {
                align-self: center;
            }
        }
    }

    .employee-month-award {
        border: 1px solid #AAAEB7;
        padding: 13px 5px 13px 16px;
        align-items: center;
        align-content: center;
        margin-bottom: 20px;
        max-width: 435px;
        cursor: pointer;

        @media (max-width: 1536px) {
            max-width: 315px;
        }

        .arrow-icon {
            width: 12px;
            height: 15px;
            @media (max-width: 1536px) {
                width: 9px;
                height: 12px;
            }
        }

    }

    .pay-items-type-description {
        padding-bottom: 20px;
    }

    .pay-items-available-soon {
        border-radius: 16px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 2px;
        background: #0160FD 0 0 no-repeat padding-box;
    }

    .span-check-box-style {
        font-size: 14px !important;
        color: #AAAEB7;
        @media (max-width: 1536px) {
            font-size: 10px !important;
        }
    }

    .create-pay-item {
        @extend .default-black-button;
        min-width: 180px !important;
        height: 50px !important;
        @media (max-width: 1536px) {
            height: 37.2px !important;
            min-width: 135px !important;
        }
    }

    .save-button {
        @extend .default-black-button;
        min-width: 200px !important;
        height: 50px !important;
        font-size: 16px !important;
        @media (max-width: 1536px) {
            height: 37.2px !important;
            min-width: 135px !important;
        }
    }
    .pay-items-margin-pay-item {
        margin-bottom: 50px;
        @media (max-width: 1536px) {
            margin-bottom: 1.388vw;
        }
    }
    .cancel-button {
        @extend .default-white-button;
        min-width: 200px !important;
        height: 50px !important;
        @media (max-width: 1536px) {
            height: 37.2px !important;
            min-width: 135px !important;
        }
    }
    .pay-items-divider {
        padding-bottom: 50px;
        padding-top: 30px;
        width: 100%;
        @media (max-width: 1536px) {
            padding-bottom: 1.472vw;
            padding-top: 1.66vw;
        }
    }

    .pay-items-margin-check-box {
        padding-top: 20px !important;
        @media (max-width: 1536px) {
            padding-top: 0 !important;
            margin-top: 0.694vw;
        }
    }

    .pay-items-payrolls-margin {
        margin-bottom: 30px;
        @media (max-width: 1536px) {
            margin-bottom: 1.66vw;
        }
    }
}

