@use "../pallet";
@use "../components/button";
@use "../components/text-field";

#forgot-password-new {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	margin-top: 5%;

	.forgot-password-new-container {
		margin-top: 5%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 3vw;
		width: fit-content;
	}
}